/**
 * Slice.
 * Copyright 2019 shininglab (https://shininglab-code.com/slice).
 */

/**
 * Create id part.
 *
 * @returns {string} Id part.
 */
const s4 = () => Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1)

/**
 * Slice.
 */
class Slice {
    /**
     * Create unique id.
     *
     * @param {object} ids - Ids object.
     * @param {string} pref - Id prefix.
     * @returns {string} Unique id.
     */
    static uniqueId (ids, pref) {
        let id = `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`
        if (pref) {
            id = pref + id
        }
        if (ids && ids[id]) {
            id = self.uniqueId(ids)
        }
        return id
    }

    /**
     * Make first letter uppercase.
     *
     * @param {string} str - Source string.
     * @returns {string} String with first letter uppercase.
     */
    static ucfirst (str) {
        return str.charAt(0).toUpperCase() + str.slice(1)
    }

    /**
     * Force browser to redraw element.
     *
     * @param {HTMLElement} element - Element to redraw.
     */
    static redraw (element) {
        if (element) {
            const disp = element.style.display
            element.style.display = 'none'
            // eslint-disable-next-line
            element.offsetHeight
            element.style.display = disp
        }
    }

    /**
     * Test whether at least one element in the array passes the test implemented by the provided function.
     *
     * @param {Array} arr - Array.
     * @param {Function} predicate - Test function.
     * @returns {boolean} Pass the test or not.
     */
    static some (arr, predicate) {
        const len = arr.length
        let i = 0
        for (; i < len; i++) {
            if (predicate(arr[i], i)) {
                return true
            }
        }
        return false
    }

    /**
     * Convert string array to object.
     *
     * @param {Array} arr - Array.
     * @returns {boolean} Pass the test or not.
     */
    static toObject (arr) {
        const obj = {}
        let i = 0
        const len = arr.length
        for (; i < len; i++) {
            obj[arr[i]] = true
        }
        return obj
    }

    /**
     * Go throught each element in array in a fastest way.
     * Array shouldn't be modified in the proccess.
     *
     * @param {Array} arr - Array.
     * @param {Function} func - Function to run for each element.
     * @returns {boolean} Pass the test or not.
     */
    static arrayEach (arr, func) {
        let i = 0
        const len = arr.length
        for (; i < len; i++) {
            func(arr[i], i)
        }
        return arr
    }

    /**
     * Go throught each element in object.
     *
     * @param {object} obj - Array.
     * @param {Function} func - Function to run for each element.
     * @returns {boolean} Pass the test or not.
     */
    static objectEach (obj, func) {
        for (const prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                func(obj[prop], prop)
            }
        }
        return obj
    }

    /**
     * Test whether at least one element in the object passes the test implemented by the provided function.
     *
     * @param {object} obj - Object.
     * @param {Function} predicate - Test function.
     * @returns {boolean} Pass the test or not.
     */
    static objectSome (obj, predicate) {
        for (const prop in obj) {
            if (obj.hasOwnProperty(prop) && predicate(obj[prop], prop)) {
                return true
            }
        }
        return false
    }

    /**
     * Check if object has accesible property.
     *
     * @param {object} obj - Object to check.
     * @param {string} prop - Property name.
     * @returns {boolean} Whether object has property or not.
     */
    static accessibleProperty (obj, prop) {
        return obj && typeof obj === 'object' && (obj.hasOwnProperty(prop) || typeof obj[prop] === 'function')
    }

    /**
     * Check if object has own property.
     *
     * @param {object} obj - Object to check.
     * @param {string} prop - Property name.
     * @returns {boolean} Whether object has property or not.
     */
    static hasProperty (obj, prop) {
        return obj && typeof obj === 'object' && obj.hasOwnProperty(prop) && typeof obj[prop] !== 'undefined'
    }

    /**
     * Get object value.
     *
     * @param {object} obj - Object to check.
     * @param {string} prop - Property name.
     * @returns {void} Object value.
     */
    static getObjectValue (obj, prop) {
        if (obj && typeof obj === 'object' && obj.hasOwnProperty(prop)) {
            return typeof obj[prop] === 'function'
                ? obj[prop]()
                : obj[prop]
        }
        return null
    }

    /**
     * Get object values.
     *
     * @param {object} obj - Object to check.
     * @param {Array} arr - Properties array.
     * @returns {object} Object values.
     */
    static getObjectValues (obj, arr) {
        if (!Array.isArray(arr)) {
            return obj
        }
        const res = {}
        Slice.arrayEach(arr, value => {
            if (obj.hasOwnProperty(value)) {
                res[value] = obj[value]
            }
        })
        return res
    }

    /**
     * Quick apply.
     *
     * @param {object} obj - Object to check.
     * @param {string} method - Object method name.
     * @param {Array} args - Arguments.
     * @returns {boolean} False if not found method result otherwise.
     */
    static quickApply (obj, method, args) {
        if (Slice.accessibleProperty(obj, method)) {
            return obj[method](...args)
        }
        return false
    }

    /**
     * Return what it get.
     *
     * @param {Array} args - Arguments.
     * @returns {Array} Passed arguments.
     */
    static noop (...args) {
        return args
    }
}


/**
 * Slice.
 * Copyright 2019 shininglab (https://shininglab-code.com/slice).
 */


/**
 * RegExp to parse px, % units.
 */
const unitRegExp = new RegExp('^((?:[+]|[-])?[0-9]+(?:[.][0-9]+)?)(.*)$')

/**
 * Slice state.
 */
class SliceUnit {
    /**
     * Parse value to unit type.
     *
     * @param {string|number} value - String value.
     * @returns {object} Object with value and type.
     */
    static toUnitType (value) {
        const unitType = {
            type: 'px',
            value: 0
        }
        if (typeof value === 'string') {
            const result = value.match(unitRegExp)
            const number = Math.floor(result[1]
                ? Number(result[1])
                : 0)
            const unit = result[2]
            switch (unit) {
            case '%':
                unitType.value = number
                unitType.type = unit
                unitType.scale = number / 100
                break
            case 'px':
            case '':
                unitType.value = number
                break
            }
        } else {
            unitType.value = value
        }
        return unitType
    }

    /**
     * From value to pixels.
     *
     * @param {string|number} value - Value to transform to pixels.
     * @param {string|number} baseValue - Value to take percent from.
     * @returns {number} Pixel value.
     */
    static toPx (value, baseValue) {
        let pxVal = 0
        if (typeof value === 'string') {
            const result = value.match(unitRegExp)
            const number = Math.floor(result[1]
                ? Number(result[1])
                : 0)
            const unit = result[2]
            switch (unit) {
            case '%':
                pxVal = number * baseValue / 100
                break
            case 'px':
            case '':
                pxVal = number
                break
            }
        } else {
            pxVal = value
        }
        return pxVal
    }

    /**
     * Calculate base value in pixels.
     *
     * @param {string|number} value - Result value.
     * @param {string|number} resValue - Value to reverce the percentage.
     * @returns {number} Base value.
     */
    static revPx (value, resValue) {
        let pxVal = 0
        if (typeof value === 'string') {
            const result = value.match(unitRegExp)
            const number = Math.floor(result[1]
                ? Number(result[1])
                : 0)
            const unit = result[2]
            switch (unit) {
            case '%':
                pxVal = number * resValue / (100 - number)
                break
            case 'px':
            case '':
                pxVal = number
                break
            }
        } else {
            pxVal = value
        }
        return pxVal
    }
}

Slice.Unit = SliceUnit


/**
 * Slice.
 * Copyright 2019 shininglab (https://shininglab-code.com/slice).
 */


/**
 * Slice state.
 */
class SliceState {
    /**
     * Slice state constructor.
     *
     * @param {object} states - Predefined states.
     */
    constructor (states) {
        this.states = {}
        this.current = {}
        if (states) {
            Slice.objectEach(states, (subStates, state) => {
                this.add(state, subStates)
            })
        }
    }

    /**
     * Add state.
     *
     * @param {string} name - State name.
     * @param {Array} subStates - The state sub states.
     * @returns {SliceState} Current instance.
     */
    add (name, subStates) {
        const subs = Slice.Tags.fromValue(subStates)
        if (this.states[name]) {
            this.states[name] = this.states[name].concat(subs)
        } else {
            this.states[name] = subs
        }
        return this
    }

    /**
     * Checks whether the slider is in a specific state or not.
     *
     * @param {string} name - State name to check.
     * @returns {boolean} True when in state, false otherwise.
     */
    is (name) {
        return this.current[name] && this.current[name] > 0
    }

    /**
     * Checks whether the slider is not at a specific state.
     *
     * @param {string} name - State name to check.
     * @returns {boolean} True when not in state, false otherwise.
     */
    not (name) {
        return !this.is(name)
    }

    /**
     * Enter a state.
     *
     * @param {string} name - State name.
     * @param {number} times - How meny times enter state.
     * @returns {SliceState} Current instance.
     */
    enter (name, times) {
        const number = times && times > 0
            ? Math.ceil(times)
            : 1
        Slice.arrayEach([name].concat(this.states[name] || []), state => {
            if (typeof this.current[state] === 'undefined') {
                this.current[state] = 0
            }
            this.current[state] += number
        })
        return this
    }

    /**
     * Leaves state.
     *
     * @param {string} name - State name.
     * @returns {SliceState} Current instance.
     */
    leave (name) {
        if (this.is(name)) {
            Slice.arrayEach([name].concat(this.states[name] || []), state => {
                this.current[state]--
            })
        }
        return this
    }

    /**
     * Leaves state.
     *
     * @param {string} name - The state name.
     * @returns {SliceState} Current instance.
     */
    exit (name) {
        if (this.is(name)) {
            const size = this.current[name]
            Slice.arrayEach([name].concat(this.states[name] || []), state => {
                this.current[state] -= size
                if (this.current[state] < 0) {
                    this.current[state] = 0
                }
            })
        }
        return this
    }

    /**
     * Reset state.
     *
     * @returns {SliceState} Current instance.
     */
    reset () {
        this.current = {}
        return this
    }
}

Slice.State = SliceState


/**
 * Slice.
 * Copyright 2019 shininglab (https://shininglab-code.com/slice).
 */


/**
 * Slice tags.
 */
class SliceTags {
    /**
     * Parse tags string to array.
     *
     * @param {string} str - Tags string.
     * @returns {Array} Tags.
     */
    static fromString (str) {
        const tags = []
        const list = str.split(',')
        let i = 0
        let tag
        for (; i < list.length; i++) {
            tag = list[i].trim()
            if (tag) {
                tags.push(tag)
            }
        }
        return tags
    }

    /**
     * Create tags array.
     *
     * @param {Array|string} value - Tags string.
     * @param {boolean} strict - Return array only.
     * @returns {Array|boolean} Tags.
     */
    static fromValue (value, strict) {
        return value === true && !strict
            ? true
            : Array.isArray(value)
                ? value
                : typeof value === 'string'
                    ? SliceTags.fromString(value)
                    : []
    }

    /**
     * Check if tag is in tags object.
     *
     * @param {object} tags - Tags object to check.
     * @param {void} tag - Tag to check.
     * @returns {boolean} Pass check.
     */
    static contains (tags, tag) {
        switch (typeof tag) {
        case 'string':
            return !Slice.some(tag.split('+'), value => !tags[value.trim()])
        default:
            return tags[tag]
        }
    }

    /**
     * Constructor.
     *
     * @param {string} [tags] - Tags.
     */
    constructor (tags) {
        this.tags = SliceTags.fromValue(tags, true)
        this.list = false
    }

    /**
     * Add tags.
     *
     * @param {string|Array} tags - Tags.
     * @returns {self} Current instance.
     */
    add (tags) {
        const builtTags = tags instanceof SliceTags
            ? tags.getAll()
            : SliceTags.fromValue(tags, true)
        this.list = false
        this.tags = this.tags.concat(builtTags)
        return this
    }

    /**
     * Check if has keys.
     *
     * @param {string|Array} [tags] - Tags.
     * @returns {boolean} Has keys.
     */
    has (tags) {
        const checkTags = tags instanceof SliceTags
            ? tags.getAll()
            : SliceTags.fromValue(tags, true)
        const list = this.getList()
        return Slice.some(checkTags, value => SliceTags.contains(list, value))
    }

    /**
     * Check if has all keys.
     *
     * @param {string|Array} [tags] - Tags.
     * @returns {boolean} Has keys.
     */
    hasAll (tags) {
        const checkTags = tags instanceof SliceTags
            ? tags.getAll()
            : SliceTags.fromValue(tags, true)
        const list = this.getList()
        return !Slice.some(checkTags, value => !SliceTags.contains(list, value))
    }

    /**
     * Check if all current tags are in provided tags.
     *
     * @param {string|Array} [tags] - Tags.
     * @returns {boolean} Has keys.
     */
    allIn (tags) {
        const list = tags instanceof SliceTags
            ? tags.getList()
            : Slice.toObject(SliceTags.fromValue(tags, true))
        const checkTags = this.tags
        return !Slice.some(checkTags, value => !SliceTags.contains(list, value))
    }

    /**
     * Get object-like list of tags.
     *
     * @returns {object} Object-like list of tags.
     */
    getList () {
        if (!this.list) {
            this.list = Slice.toObject(this.tags)
        }
        return this.list
    }

    /**
     * Get array of tags.
     *
     * @returns {Array} Tags array.
     */
    getAll () {
        return this.tags
    }
}

Slice.Tags = SliceTags


/**
 * Slice.
 * Copyright 2019 shininglab (https://shininglab-code.com/slice).
 */


/**
 * Create flat array of promises.
 *
 * @param {number} depth - Flatting depth.
 * @param {Array} args - Promises list.
 * @returns {Array} Flat array of promises.
 */
const flatPromises = (depth, ...args) => {
    const promises = []
    Slice.arrayEach(args, promise => {
        if (typeof promise === 'object' && typeof promise.then === 'function') {
            promises.push(promise)
        } else if (depth && Array.isArray(promise)) {
            promises.push(...flatPromises(depth - 1, ...promise))
        } else {
            promises.push(SlicePromise.resolve(promise))
        }
    })
    return promises
}

/**
 * Run functions of provided type.
 *
 * @param {Array} fns - Functions list.
 * @param {Array|string} types - Functions type.
 * @param {void} result - Promise result.
 */
const callFns = (fns, types, result) => {
    let runTypes
    if (Array.isArray(types)) {
        runTypes = Slice.toObject(types)
    } else if (typeof types === 'string') {
        runTypes = {}
        runTypes[types] = true
    }
    Slice.arrayEach(fns, fn => {
        if (runTypes[fn.type]) {
            fn.fn(result)
        }
    })
}

/**
 * Add functions of provided type.
 *
 * @param {string} type - Functions type.
 * @param {Array} toAdd - Functions to add.
 * @param {Array} fns - Optional list of already added functions.
 * @returns {Array} - Added functions list or list.
 */
const createFns = (type, toAdd, fns) => {
    const addedList = Array.isArray(fns)
        ? fns
        : []
    Slice.arrayEach(toAdd, fn => {
        if (typeof fn === 'function') {
            const fnData = {
                fn,
                type
            }
            addedList.push(fnData)
        } else if (Array.isArray(fn)) {
            createFns(type, fn, addedList)
        }
    })
    return addedList
}

/**
 * Add functions of provided type.
 *
 * @param {Array} fns - Functions list.
 * @param {string} type - Functions type.
 * @param {Array} toAdd - Functions to add.
 * @param {boolean} noAdd - Allow adding.
 * @param {boolean} run - Run now.
 * @param {void} runResult - Run arguments.
 */
const addFns = (fns, type, toAdd, noAdd, run, runResult) => {
    const addedList = createFns(type, toAdd)
    if (!noAdd) {
        fns.push(...addedList)
    }
    if (run) {
        callFns(addedList, type, runResult)
    }
}

/**
 * Reject.
 *
 * @param {SlicePromise} promise - Promise object.
 * @param {void} value - Resolve value.
 * @returns {self} Current instance.
 */
const reject = (promise, value) => {
    if (promise.state.not('completed')) {
        // Enter rejected state.
        promise.state.reset().enter('rejected')
        const fns = promise.fns
        promise.fns = []
        promise.result = value
        callFns(fns, ['fail', 'always'], value)
    }
    return promise
}

/**
 * Resolve.
 *
 * @param {SlicePromise} promise - Promise object.
 * @param {void} value - Resolve value.
 * @returns {self} Current instance.
 */
const resolve = (promise, value) => {
    if (promise.state.not('completed')) {
        // Enter resolved state.
        promise.state.reset().enter('resolved')
        const fns = promise.fns
        promise.fns = []
        promise.result = value
        callFns(fns, ['done', 'always'], value)
    }
    return promise
}

/**
 * Slice deferred.
 */
class SlicePromise {
    /**
     * Slice deferred constructor.
     *
     * @param {Function} func - Function to resolve or reject promise.
     */
    constructor (func) {
        this.state = new SliceState({
            plain: 'pending',
            rejected: 'completed, run, failed, triggered',
            resolved: 'completed, run, fulfilled, triggered'
        })
        this.state.enter('plain')
        this.fns = []
        const resolvePromise = value => {
            resolve(this, value)
        }
        const rejectPromise = value => {
            reject(this, value)
        }
        func.call(this, resolvePromise, rejectPromise)
    }

    /**
     * Add callables that will be executed always.
     *
     * @param {Array} args - Array of functions.
     * @returns {self} Current SlicePromise.
     */
    always (...args) {
        const completed = this.state.is('completed')
        addFns(this.fns, 'always', args, completed, completed, this.result)
        return this
    }

    /**
     * Add callables that will be executed always.
     * The difference between 'always' and 'finaly' is that 'finaly' returns new SlicePromise.
     *
     * @param {Array} args - Array of functions.
     * @returns {self} New SlicePromise.
     */
    finaly (...args) {
        let resPromise
        const promise = new SlicePromise(res => {
            resPromise = res
        })
        args.push(result => {
            resPromise(result)
        })
        this.always(...args)
        return promise
    }

    /**
     * Add handlers that will be called when postponed is resolved.
     *
     * @param {Array} args - Array of functions.
     * @returns {self} Current SlicePromise.
     */
    done (...args) {
        addFns(this.fns, 'done', args, this.state.is('completed'), this.state.is('resolved'), this.result)
        return this
    }

    /**
     * Add handlers that will be called when postponed is rejected.
     *
     * @param {Array} args - Array of functions.
     * @returns {self} Current SlicePromise.
     */
    fail (...args) {
        addFns(this.fns, 'fail', args, this.state.is('completed'), this.state.is('rejected'), this.result)
        return this
    }

    /**
     * Add handlers that will be called when postponed is rejected.
     * The difference between 'fail' and 'catch' is that 'catch' returns new SlicePromise.
     *
     * @param {Array} args - Array of functions.
     * @returns {self} New SlicePromise.
     */
    catch (...args) {
        return this.then(null, args)
    }

    /**
     * Add handlers that will be called when postponed is resolved, rejected, or still in progress.
     *
     * @param {Function|Array} doneFn - A function or array of functions that is called when promise is resolved.
     * @param {Function|Array} failFn - An optional function or array of functions that is called
     * when promise is rejected.
     * @returns {self} New SlicePromise.
     */
    then (doneFn, failFn) {
        let resPromise, rejPromise
        const promise = new SlicePromise((res, rej) => {
            resPromise = res
            rejPromise = rej
        })
        const doneFns = Array.isArray(doneFn)
            ? doneFn
            : [doneFn]
        const failFns = Array.isArray(failFn)
            ? failFn
            : [failFn]
        doneFns.push(result => {
            resPromise(result)
        })
        failFns.push(result => {
            rejPromise(result)
        })
        const completed = this.state.is('completed')
        addFns(this.fns, 'done', doneFns, completed, this.state.is('resolved'), this.result)
        addFns(this.fns, 'fail', failFns, completed, this.state.is('rejected'), this.result)
        return promise
    }

    /**
     * Creates new SlicePromise that is already resolved.
     *
     * @param {void} result - Resolve result.
     * @returns {SlicePromise} New SlicePromise.
     */
    static resolve (result) {
        return new SlicePromise(res => {
            res(result)
        })
    }

    /**
     * Creates new SlicePromise that is already rejected.
     *
     * @param {void} result - Rejection result.
     * @returns {SlicePromise} New SlicePromise.
     */
    static reject (result) {
        return new SlicePromise(res => {
            res(result)
        })
    }

    /**
     * Creates new SlicePromise that is resolved when all provided promises are resolved
     * or rejected when atleast one is rejected.
     *
     * @param {Array} args - Array of promises.
     * @returns {SlicePromise} New SlicePromise.
     */
    static all (...args) {
        const promises = flatPromises(1, args)
        let pendingLen = promises.length
        let resArgs = []
        let resPromise, rejPromise
        const retPromise = new SlicePromise((res, rej) => {
            resPromise = res
            rejPromise = rej
        })
        if (promises.length) {
            Slice.arrayEach(promises, promise => {
                promise
                    .then(result => {
                        if (pendingLen) {
                            --pendingLen
                            resArgs.push(result)
                            if (pendingLen === 0) {
                                resPromise(resArgs)
                            }
                        }
                    }, result => {
                        if (pendingLen) {
                            pendingLen = null
                            resArgs = null
                            rejPromise(result)
                        }
                    })
            })
        } else {
            resPromise()
        }
        return retPromise
    }

    /**
     * Creates new SlicePromise that is resolved when atleast one of provided promises is resolved
     * or rejected when all are rejected.
     *
     * @param {Array} args - Array of promises.
     * @returns {SlicePromise} New SlicePromise.
     */
    static any (...args) {
        const promises = flatPromises(1, args)
        let pendingLen = promises.length
        let resArgs = []
        let resPromise, rejPromise
        const retPromise = new SlicePromise((res, rej) => {
            resPromise = res
            rejPromise = rej
        })
        if (promises.length) {
            Slice.arrayEach(promises, promise => {
                promise
                    .then(result => {
                        if (pendingLen) {
                            pendingLen = null
                            resArgs = null
                            resPromise(result)
                        }
                    }, result => {
                        if (pendingLen) {
                            --pendingLen
                            resArgs.push(result)
                            if (pendingLen === 0) {
                                rejPromise(resArgs)
                            }
                        }
                    })
            })
        } else {
            resPromise()
        }
        return retPromise
    }

    /**
     * Creates new SlicePromise that is resolved when all provided promises are either resolved
     * or rejected.
     *
     * @param {Array} args - Array of promises.
     * @returns {SlicePromise} New SlicePromise.
     */
    static allSettled (...args) {
        const promises = flatPromises(1, args)
        let pendingLen = promises.length
        const resArgs = []
        let resPromise
        const retPromise = new SlicePromise(res => {
            resPromise = res
        })
        if (promises.length) {
            Slice.arrayEach(promises, promise => {
                promise
                    .then(result => {
                        if (pendingLen) {
                            --pendingLen
                            resArgs.push({
                                status: 'fulfilled',
                                value: result
                            })
                            if (pendingLen === 0) {
                                resPromise(resArgs)
                            }
                        }
                    }, result => {
                        if (pendingLen) {
                            --pendingLen
                            resArgs.push({
                                reason: result,
                                status: 'rejected'
                            })
                            if (pendingLen === 0) {
                                resPromise(resArgs)
                            }
                        }
                    })
            })
        } else {
            resPromise()
        }
        return retPromise
    }

    /**
     * Creates new SlicePromise that is resolved or rejected as soon as one of the promises is resolved or rejected.
     *
     * @param {Array} args - Array of promises.
     * @returns {SlicePromise} New SlicePromise.
     */
    static race (...args) {
        const promises = flatPromises(1, args)
        let pending = true
        let resPromise, rejPromise
        const retPromise = new SlicePromise((res, rej) => {
            resPromise = res
            rejPromise = rej
        })
        Slice.arrayEach(promises, promise => {
            promise
                .then(result => {
                    if (pending) {
                        pending = false
                        resPromise(result)
                    }
                }, result => {
                    if (pending) {
                        pending = false
                        rejPromise(result)
                    }
                })
        })
        return retPromise
    }
}

Slice.Promise = SlicePromise


/**
 * Slice.
 * Copyright 2019 shininglab (https://shininglab-code.com/slice).
 */


/**
 * Slice deferred.
 */
class SliceDeferred extends SlicePromise {
    /**
     * Constructor.
     */
    constructor () {
        // Add resolve and reject methods
        super(function (resolve, reject) {
            this.resolve = resolve
            this.reject = reject
        })
    }

    /**
     * Get deferred promise.
     *
     * @returns {SlicePromise} Current SliceDeferred promise.
     */
    promise () {
        if (!this.promise) {
            this.promise = SlicePromise.race(this)
        }
        return this.promise
    }
}

Slice.Deferred = SliceDeferred


/**
 * Slice.
 * Copyright 2019 shininglab (https://shininglab-code.com/slice).
 *
 * @author shininglab
 * @typedef {object} Promise Promise object (jQuery)
 * @typedef {object} Deferred Deferred object (jQuery)
 * @typedef {SlicePostponed|Deferred|Promise} Postponed Postponed object
 */


/**
 * Run postponed.
 *
 * @param {SlicePostponed} postponed - Postponed object.
 * @param {Postponed|Function} item - Runnable item.
 * @returns {Promise} Promise.
 */
function runPostponed (postponed, item) {
    let promise
    switch (typeof item) {
    case 'object':
        if (item instanceof SlicePostponed) {
            item.run()
        }
        if (item.always) {
            promise = item
        } else {
            promise = SlicePromise.resolve(item)
        }
        break
    case 'function':
        promise = runPostponed(postponed, item())
        break
    default:
        promise = SlicePromise.resolve(item)
        break
    }
    return promise
}

/**
 * Run postponed and remove it.
 *
 * @param {SlicePostponed} postponed - Postponed object.
 * @param {Postponed|Function} item - Runnable item.
 * @returns {Promise} Promise.
 */
function runPostponedAndRemove (postponed, item) {
    const promise = runPostponed(postponed, item)
    postponed.active.push(promise)
    return promise.always(() => {
        postponed.remove(promise)
    })
}

/**
 * Run next postponed.
 *
 * @param {SlicePostponed} postponed - Postponed object.
 * @param {Postponed|Function} item - Runnable item.
 */
function runNext (postponed, item) {
    const promise = runPostponed(postponed, item)
    postponed.active.push(promise)
    promise.always(() => {
        postponed.remove(promise)
        postponed.runNext()
    })
}

/**
 * Run postponed immidiately.
 *
 * @param {SlicePostponed} postponed - Postponed object.
 * @param {Postponed|Function} item - Runnable item.
 */
function runImmidiate (postponed, item) {
    if (postponed.maxActive) {
        runNext(postponed, item)
    } else {
        runPostponedAndRemove(postponed, item)
    }
}

/**
 * Refresh postponed.
 *
 * @param {SlicePostponed} postponed - Postponed object.
 */
function refreshData (postponed) {
    postponed.promise = null
    postponed.fns = []
    postponed.result = null
    postponed
        .done(result => {
            const resArgs = [result]
            // Resolve after.
            Slice.quickApply(postponed.after, 'resolve', resArgs)
            // Resolve all items.
            const items = postponed.active.concat(postponed.items)
            postponed.items = []
            postponed.state.enter('plain')
            Slice.arrayEach(items, item => {
                Slice.quickApply(item, 'resolve', resArgs)
            })
        })
        .fail(result => {
            const resArgs = [result]
            // Reject after.
            Slice.quickApply(postponed.after, 'reject', resArgs)
            // Reject all items.
            const items = postponed.active.concat(postponed.items)
            postponed.items = []
            postponed.state.enter('plain')
            Slice.arrayEach(items, item => {
                switch (typeof item) {
                case 'object':
                    Slice.quickApply(item, 'reject', resArgs)
                    break
                case 'function':
                    item()
                    break
                }
            })
        })
}

/**
 * Refresh postponed.
 *
 * @param {SlicePostponed} postponed - Postponed object.
 */
function refresh (postponed) {
    if (postponed.state.is('triggered')) {
        if (postponed.state.is('completed')) {
            postponed.state.exit('rejected').exit('resolved')
            refreshData(postponed)
            postponed.run()
        } else if (postponed.state.is('queued')) {
            postponed.runNext()
        } else if (postponed.state.is('running')) {
            const active = postponed.items
            postponed.items = []
            Slice.arrayEach(active, item => {
                runPostponedAndRemove(postponed, item)
            })
        }
    }
}

/**
 * Slice postponed.
 */
class SlicePostponed extends SliceDeferred {
    /**
     * Constructor.
     *
     * @param {number} [maxActive] - Maximum active executions.
     */
    constructor (maxActive) {
        super()
        refreshData(this)
        this.active = []
        this.maxActive = maxActive || 0
        this.state
            .add('queue', 'pending')
            .add('queued', 'run, triggered')
            .add('runAfter', 'run, pending')
            .add('running', 'run, triggered')
        this.items = []
        this.length = 0
    }

    /**
     * Set previous postponed object.
     *
     * @param {Postponed} variable - Postponed object.
     * @param {boolean} linked - Link to each other.
     * @returns {self} Current instance.
     */
    runAfter (variable, linked) {
        const promise = SlicePromise.race(variable)
        this.state.enter('runAfter')
        if (linked) {
            this.after = variable
            promise.always(() => {
                this.after = null
            })
        }
        if (this.state.not('triggered')) {
            promise
                .always(() => {
                    this.state.exit('runAfter')
                })
                .done(() => {
                    this.run()
                })
                .fail((...args) => {
                    this.reject.apply(...args)
                })
        }
        return this
    }

    /**
     * Set maximum active executions number.
     *
     * @param {number} value - Executions number.
     * @returns {self} Current instance.
     */
    setMaxActive (value) {
        if (this.maxActive !== value) {
            this.maxActive = value
            if (this.state.is('queued')) {
                this.runNext()
            }
        }
        return this
    }

    /**
     * Check finished.
     * Cleanup everything if all items was executed.
     *
     * @returns {self} Current instance.
     */
    checkFinish () {
        if (this.state.is('running') && this.state.not('queue')) {
            this.resolve('completed')
        }
        return this
    }

    /**
     * Add item.
     *
     * @param {Postponed|Function} item - Runnable item.
     * @param {boolean} forward - Forward item.
     * @param {boolean} immidiate - Run item immidiately.
     * @returns {self} Current instance.
     */
    add (item, forward, immidiate) {
        if (this.state.is('plain')) {
            this.state.exit('plain')
        }
        this.state.enter('queue')
        if (immidiate) {
            runImmidiate(this, item)
        } else {
            const action = forward
                ? 'unshift'
                : 'push'
            this.items[action](item)
            refresh(this)
        }
        return this
    }

    /**
     * Add items range.
     *
     * @param {Array<Postponed|Function>} list - Array of items.
     * @param {boolean} forward - Forward item.
     * @param {boolean} immidiate - Run item immidiately.
     * @returns {self} Current instance.
     */
    addRange (list, forward, immidiate) {
        if (this.state.is('plain')) {
            this.state.exit('plain')
        }
        this.state.enter('queue', list.length)
        if (immidiate) {
            Slice.arrayEach(list, item => {
                runImmidiate(this, item)
            })
        } else {
            this.items = forward
                ? list.concat(this.items)
                : this.items.concat(list)
            refresh(this)
        }
        return this
    }

    /**
     * Run, execute all items.
     *
     * @returns {self} Current instance.
     */
    run () {
        if (this.state.not('triggered')) {
            this.state.enter('running')
            if (this.maxActive) {
                this.state.enter('queued')
                this.runNext()
            } else {
                const active = this.items
                this.items = []
                Slice.arrayEach(active, item => {
                    runPostponedAndRemove(this, item)
                })
                this.checkFinish()
            }
        } else if (this.state.is('queued')) {
            this.runNext()
        }
        return this
    }

    /**
     * Run next item.
     *
     * @returns {self} Current instance.
     */
    runNext () {
        this.checkFinish()
        if (this.state.not('completed')) {
            if (!this.maxActive || this.state.not('running')) {
                return this.run()
            }
            Slice.arrayEach(this.items.splice(
                0,
                Math.max(0, Math.min(this.maxActive - this.active.length, this.items.length))
            ), item => {
                runNext(this, item)
            })
        }
        return this
    }

    /**
     * Remove item.
     *
     * @param {Postponed|Function} item - Runnable item.
     * @returns {self} Current instance.
     */
    remove (item) {
        let removed = false
        let position = this.items.indexOf(item)
        if (position >= 0) {
            removed = true
            this.items.splice(position, 1)
        }
        position = this.active.indexOf(item)
        if (position >= 0) {
            removed = true
            this.active.splice(position, 1)
        }
        if (removed) {
            this.state.leave('queue')
            this.checkFinish()
        }
        return this
    }
}

Slice.Postponed = SlicePostponed


/**
 * Slice.
 * Copyright 2019 shininglab (https://shininglab-code.com/slice).
 */


/**
 * Slice workflow.
 */
class SliceWorkflow {
    /**
     * Slice Workflow constructor.
     *
     * @class SliceWorkflow
     * @param {Array} workers - List of workers.
     * @param {boolean|string} caching - Workflow caching.
     * @param {object} [quick] - Quick executions list.
     */
    constructor (workers, caching, quick) {
        this.workers = workers
        this.quick = quick || {}
        switch (caching) {
        case 'all':
            this.cache = {}
            this.quickCache = {}
            break
        case 'quick':
            this.quickCache = {}
            break
        case 'no-cache':
            this.cache = false
            this.quickCache = false
            break
        default:
            this.cache = caching
                ? {}
                : false
            this.quickCache = caching
                ? {}
                : false
            break
        }
    }

    /**
     * Create worker.
     *
     * @param {Array|string} keys - Worker keys.
     * @param {Function} fn - Worker funtion to run.
     * @param {number} [priority] - The worker priority, of not set - 0.
     * @returns {object} Worker object.
     */
    static createWorker (keys, fn, priority) {
        if (typeof fn !== 'function') {
            return null
        }
        return {
            keys,
            priority: priority || 0,
            run: fn
        }
    }

    /**
     * Default worker execution function.
     *
     * @param {object} worker - Worker object.
     * @param {object} cache - Flow cache.
     * @returns {object} Execution result.
     */
    static runWorker (worker, cache) {
        return worker.run(cache)
    }

    /**
     * Build worker.
     *
     * @param {object} worker - Worker object.
     * @returns {object} Worker object.
     */
    static buildWorker (worker) {
        if (!worker.built) {
            worker.keys = Slice.Tags.fromValue(worker.keys)
            worker.built = true
        }
        return worker
    }

    /**
     * Handle flow result.
     *
     * @param {object} flow - Flow object.
     * @param {object} result - Flow result.
     * @returns {object} Flow result.
     */
    static handleResult (flow, result) {
        if (flow.asObject) {
            return Slice.getObjectValues(result, flow.expected)
        }
        return result[flow.expected]
    }

    /**
     * Run flow.
     *
     * @param {object} flow - Flow object.
     * @param {object} data - Flow data.
     * @param {Function} [fn] - Execute function.
     * @returns {object} Flow result.
     */
    static runFlow (flow, data, fn) {
        const execute = typeof fn === 'function'
            ? fn
            : SliceWorkflow.runWorker
        if (!flow.cache) {
            flow.cache = {}
        }
        flow.cache = {...data || {}}
        flow.cache.list = flow.list
        Slice.some(flow.workers, worker => execute.call(this, worker, flow.cache) === false)
        return SliceWorkflow.handleResult(flow, flow.cache)
    }

    /**
     * Get flow settings.
     *
     * @param {Array|string} keys - Worker keys.
     * @returns {object} Flow settings.
     */
    getFlowSettings (keys) {
        let asObject = true
        let arrKeys, expected
        if (typeof keys === 'string' && this.quick[keys]) {
            ({
                asObject,
                expected,
                keys: arrKeys
            } = this.quick[keys])
        } else {
            arrKeys = Slice.Tags.fromValue(keys, true)
        }
        // Convert array to object for faster go-throught
        const list = Slice.toObject(arrKeys)
        return {
            asObject,
            expected,
            filter: function (key) {
                return Slice.Tags.contains(this, key)
            }.bind(list),
            keys: arrKeys,
            list
        }
    }

    /**
     * Get workers flow.
     *
     * @param {Array|string} keys - Worker keys.
     * @returns {object} Flow object with workers and settings.
     */
    getFlow (keys) {
        const flow = this.getFlowSettings(keys)
        const keysLen = flow.keys.length
        flow.workers = []
        Slice.arrayEach(this.workers, worker => {
            SliceWorkflow.buildWorker(worker)
            if (worker.keys === true || keysLen && Slice.some(worker.keys, flow.filter)) {
                flow.workers.push(worker)
            }
        })
        return flow
    }

    /**
     * Get cached flow.
     *
     * @param {Array|string} keys - Worker keys.
     * @returns {object} Flow object with workers and settings.
     */
    getCachedFlow (keys) {
        if (!this.cache) {
            return null
        }
        let cache, cacheKey
        if (typeof keys === 'string' && this.quick[keys]) {
            cache = 'quickCache'
            cacheKey = keys
        } else {
            cacheKey = Array.isArray(keys)
                ? keys.join(', ')
                : `${keys}`
            cache = 'cache'
        }
        if (!this[cache]) {
            return null
        }
        if (!this[cache].hasOwnProperty(cacheKey)) {
            this[cache][cacheKey] = this.getFlow(keys)
        }
        return this[cache][cacheKey]
    }

    /**
     * Run all workers.
     *
     * @param {object} data - Workflow data.
     * @param {Function} fn - Execute function.
     * @returns {object} Workflow result.
     */
    runAll (data, fn) {
        return SliceWorkflow.runFlow({
            asObject: true,
            list: {
                all: true
            },
            workers: this.workers
        }, data, fn)
    }

    /**
     * Run workflow part.
     *
     * @param {Array|string} keys - Workflow keys.
     * @param {object} data - Workflow data.
     * @param {Function} fn - Execute function.
     * @returns {object} Workflow result.
     */
    run (keys, data, fn) {
        const flow = this.getCachedFlow(keys, true)
        if (flow === null) {
            this.runDirect(keys, fn)
        }
        return SliceWorkflow.runFlow(flow, data, fn)
    }

    /**
     * Run workflow part with no caching.
     *
     * @param {Array|string} keys - Workflow keys.
     * @param {object} data - Workflow data.
     * @param {Function} fn - Execute function.
     * @returns {object} Workflow result.
     */
    runDirect (keys, data, fn) {
        const flow = this.getFlowSettings(keys)
        const execute = typeof fn === 'function'
            ? fn
            : SliceWorkflow.runWorker
        const keysLen = flow.keys.length
        const cache = {...data || {}}
        cache.list = flow.list
        Slice.some(this.workers, worker =>
            (worker.keys === true || keysLen && Slice.some(worker.keys, flow.filter)) &&
            execute.call(this, worker, cache) === false)
        return SliceWorkflow.handleResult(flow, cache)
    }
}

Slice.Workflow = SliceWorkflow


/**
 * Slice.
 * Copyright 2019 shininglab (https://shininglab-code.com/slice).
 */


/**
 * Slice workflow factory.
 */
class SliceWorkflowFactory {
    /**
     * Slice Workflow constructor.
     *
     * @class SliceWorkflowFactory
     */
    constructor () {
        this.resetRequirements()
        this.workers = []
        this.runWorkers = []
        this.runnableType = false
        this.quickRunList = {}
        this.quickRunToType = {}
        // Force this on some methods
        this.addWorker = this.addWorker.bind(this)
        this.addRunnable = this.addRunnable.bind(this)
        this.setRunnableType = this.setRunnableType.bind(this)
        this.resetRunnableType = this.resetRunnableType.bind(this)
        this.setQuickRun = this.setQuickRun.bind(this)
        this.removeQuickRun = this.removeQuickRun.bind(this)
        this.addRequirement = this.addRequirement.bind(this)
        this.removeRequirement = this.removeRequirement.bind(this)
        this.resetRequirements = this.resetRequirements.bind(this)
    }

    /**
     * Parse requirements from string.
     *
     * @param {string} str - Stringified requirements.
     * @returns {object} Requirements object.
     */
    static parseRequirements (str) {
        const requirements = {}
        Slice.arrayEach(str.split(','), rawReq => {
            let requirement = rawReq.trim()
            let value = true
            if (requirement.slice(0, 1) === '!') {
                value = false
                requirement = requirement.slice(1).trim()
            }
            if (requirement) {
                requirements[requirement] = value
            }
        })
        return requirements
    }

    /**
     * Reset default requirements for workers.
     */
    resetRequirements () {
        this.presetRequirements = {}
        this.presetRequirementsCount = 0
    }

    /**
     * Add default requirements for workers.
     *
     * @param {string|object} requirement - Required state/states list.
     */
    addRequirement (requirement) {
        let requirements
        if (typeof requirement === 'string') {
            requirements = SliceWorkflowFactory.parseRequirements(requirement)
        } else if (typeof requirement === 'object') {
            requirements = requirement
        }
        if (requirements) {
            Slice.objectEach(requirements, (value, req) => {
                if (!this.presetRequirements.hasOwnProperty(req)) {
                    ++this.presetRequirementsCount
                }
                this.presetRequirements[req] = value
            })
        }
    }

    /**
     * Remove default requirements for workers.
     *
     * @param {string|object} requirement - Required state/states list.
     */
    removeRequirement (requirement) {
        let requirements
        if (typeof requirement === 'string') {
            requirements = SliceWorkflowFactory.parseRequirements(requirement)
        } else if (typeof requirement === 'object') {
            requirements = requirement
        }
        if (requirements) {
            Slice.objectEach(requirements, (value, req) => {
                if (this.presetRequirements.hasOwnProperty(req) &&
                    this.presetRequirements[req] === requirements[req]) {
                    delete this.presetRequirements[req]
                    --this.presetRequirementsCount
                }
            })
        }
    }

    /**
     * Create worker requirements.
     *
     * @param {boolean|string|object|Array} [value] - Requirements list.
     * @returns {boolean|object|Array} Requirements object.
     */
    createRequirements (value) {
        if (this.presetRequirementsCount || value) {
            let requirements
            if (Array.isArray(value)) {
                requirements = []
                Slice.arrayEach(value, requirement => {
                    requirements.push(this.createRequirements(requirement))
                })
            } else {
                requirements = {...this.presetRequirements || {}}
                switch (typeof value) {
                case 'string':
                    requirements = Object.assign(requirements, SliceWorkflowFactory.parseRequirements(value))
                    break
                case 'object':
                    requirements = Object.assign(requirements, value)
                    break
                default:
                    requirements = this.presetRequirementsCount
                        ? requirements
                        : !!value
                    break
                }
            }
            return requirements
        }
        return false
    }

    /**
     * Create worker.
     *
     * @param {Array|string} keys - Worker keys.
     * @param {Function} fn - Worker funtion to run.
     * @param {number} [priority] - The worker priority, of not set - 0.
     * @param {boolean|string|object|Array} [requirements] - Worker requirements list.
     * @returns {object} Worker object.
     */
    createWorker (keys, fn, priority, requirements) {
        const worker = SliceWorkflow.createWorker(keys, fn, priority)
        if (worker) {
            worker.requirements = this.createRequirements(requirements)
        }
        return worker
    }

    /**
     * Add worker.
     *
     * @param {Array|string} keys - Worker keys.
     * @param {Function} fn - Worker funtion to run.
     * @param {number} [priority] - The worker priority, of not set - 0.
     * @param {boolean|string|object|Array} [enabled] - Enable/disable worker requirements list.
     * @returns {SliceWorkflowFactory} Current SliceWorkflowFactory.
     */
    addWorker (keys, fn, priority, enabled) {
        const worker = this.createWorker(keys, fn, priority, enabled)
        if (worker) {
            this.workers.push(worker)
        }
        return this
    }

    /**
     * Set default runnable type.
     *
     * @param {string} name - Plugin name.
     * @returns {SliceWorkflowFactory} Current SliceWorkflowFactory.
     */
    setRunnableType (name) {
        this.runnableType = `${name}`
        return this
    }

    /**
     * Reset default runnable type.
     *
     * @returns {SliceWorkflowFactory} Current SliceWorkflowFactory.
     */
    resetRunnableType () {
        this.runnableType = false
        return this
    }

    /**
     * Add runnable worker.
     *
     * @param {Array|string} keys - Worker keys.
     * @param {Function} fn - Worker funtion to run.
     * @param {number} [priority] - The worker priority, of not set - 0.
     * @param {boolean|string|object|Array} [enabled] - Enable/disable worker requirements list.
     * @param {string} [workerType] - Worker type, when not set or true - default|core workers type.
     */
    addRunnable (keys, fn, priority, enabled, workerType) {
        const worker = this.createWorker(keys, fn, priority, enabled)
        const type = !(workerType || this.runnableType) || workerType === true
            ? '_'
            : workerType || this.runnableType
        if (!worker) {
            return
        }
        if (!this.runWorkers[type]) {
            this.runWorkers[type] = []
        }
        this.runWorkers[type].push(worker)
    }

    /**
     * Set/Add quick runnable.
     *
     * @param {string} name - Quick runnable name.
     * @param {Array|string} [keys] - Workers keys.
     * @param {string|Array} [expected] - Expected value(s) to return.
     * @param {string} [workerType] - Worker type, when not set or true - default|core workers type.
     */
    setQuickRun (name, keys, expected, workerType) {
        this.removeQuickRun(name)
        const type = !(workerType || this.runnableType) || workerType === true
            ? '_'
            : workerType || this.runnableType
        let exp
        if (expected) {
            exp = expected
            if (typeof exp === 'string') {
                exp = SliceTags.fromString(exp)
                if (exp.length === 1) {
                    [exp] = exp
                }
            }
        } else {
            exp = 'result'
        }
        if (!this.quickRunList.hasOwnProperty(type)) {
            this.quickRunList[type] = {}
        }
        this.quickRunToType[name] = type
        this.quickRunList[type][name] = {
            asObject: Array.isArray(exp),
            expected: exp,
            keys: SliceTags.fromValue(keys)
        }
    }

    /**
     * Remove quick runnable.
     *
     * @param {string} name - Quick runnable name.
     */
    removeQuickRun (name) {
        if (this.quickRunToType[name]) {
            delete this.quickRunList[this.quickRunToType[name]][name]
            delete this.quickRunToType[name]
        }
    }

    /**
     * Check worker requirements.
     *
     * @param {object} requirements - Requirements to check.
     * @param {object} check - Requirements check.
     * @returns {boolean} Pass or not.
     */
    static checkRequirements (requirements, check) {
        const enabled = !!check
        if (Array.isArray(requirements)) {
            if (Slice.some(requirements, value => SliceWorkflowFactory.checkRequirements(value, check))) {
                return enabled
            }
            return false
        }
        if (typeof requirements === 'object') {
            for (const requirement in requirements) {
                if (requirements[requirement] !== (typeof requirements[requirement] === 'boolean'
                    ? !!check[requirement]
                    : check[requirement])) {
                    return false
                }
            }
        }
        return enabled
    }

    /**
     * Filter workers by requirements.
     *
     * @param {object} list - List of workers.
     * @param {object} requirements - Workers requirements.
     * @returns {object} Filtered workers list.
     */
    static filterWorkers (list, requirements) {
        let workers
        if (requirements) {
            workers = list.filter(worker => SliceWorkflowFactory.checkRequirements(worker.requirements, requirements))
        } else {
            workers = list.slice(0)
        }
        workers.sort((item1, item2) => item1.priority - item2.priority)
        return workers
    }

    /**
     * Get workers.
     *
     * @param {object} requirements - Workers requirements.
     * @returns {object} Workers list.
     */
    createManager (requirements) {
        const manager = new SliceWorkflowManager(new SliceWorkflow(
            SliceWorkflowFactory.filterWorkers(this.workers, requirements),
            true
        ))
        Slice.objectEach(this.runWorkers, (list, key) => {
            manager.addRunflow(key, new SliceWorkflow(
                SliceWorkflowFactory.filterWorkers(list, requirements),
                true, this.quickRunList[key]
            ))
        })
        manager.quickRunList = this.quickRunList
        manager.quickRunToType = this.quickRunToType
        return manager
    }
}

Slice.WorkflowFactory = SliceWorkflowFactory


/**
 * Slice.
 * Copyright 2019 shininglab (https://shininglab-code.com/slice).
 */


/**
 * Slice workflow manager.
 */
class SliceWorkflowManager {
    /**
     * Slice workflow manager constructor.
     *
     * @param {SliceWorkflow} workflow - SliceWorkflow object.
     * @param {object} runflows - List of runnable SliceWorkflow.
     */
    constructor (workflow, runflows) {
        this.workflow = null
        this.runflows = {}
        this.quickRunList = {}
        this.quickRunToType = {}
        if (workflow) {
            this.setWorkflow(workflow)
        }
        if (typeof runflows === 'object') {
            Slice.objectEach(runflows, (runflow, name) => {
                this.addRunflow(name, runflow)
            })
        }
    }

    /**
     * Set workerflow.
     *
     * @param {SliceWorkflow} workflow - SliceWorkflow object.
     * @returns {SliceWorkflowManager} Current SliceWorkflowManager.
     */
    setWorkflow (workflow) {
        if (workflow instanceof SliceWorkflow) {
            this.workflow = workflow
        }
        return this
    }

    /**
     * Add runnable workerflow.
     *
     * @param {string} name - Runflow name.
     * @param {SliceWorkflow} runflow - Runnable SliceWorkflow object.
     * @returns {SliceWorkflowManager} Current SliceWorkflowManager.
     */
    addRunflow (name, runflow) {
        if (runflow instanceof SliceWorkflow) {
            this.runflows[name] = runflow
        }
        return this
    }

    /**
     * Remove runnable workerflow.
     *
     * @param {string} name - Runflow name.
     * @returns {SliceWorkflowManager} Current SliceWorkflowManager.
     */
    removeRunflow (name) {
        if (this.runflows.hasOwnProperty(name)) {
            delete this.runflows[name]
        }
        return this
    }

    /**
     * Set/Add quick runnable.
     *
     * @param {string} name - Quick runnable name.
     * @param {Array|string} [keys] - Workers keys.
     * @param {string|Array} [expected] - Expected value(s) to return.
     * @param {string} [workerType] - Worker type, when not set or true - default|core workers type.
     */
    setQuickRun (name, keys, expected, workerType) {
        this.removeQuickRun(name)
        const type = !workerType || workerType === true
            ? '_'
            : workerType || false
        let exp
        if (expected) {
            exp = expected
            if (typeof exp === 'string') {
                exp = SliceTags.fromString(exp)
                if (exp.length === 1) {
                    [exp] = exp
                }
            }
        } else {
            exp = 'result'
        }
        if (!this.quickRunList.hasOwnProperty(type)) {
            this.quickRunList[type] = {}
        }
        this.quickRunToType[name] = type
        this.quickRunList[type][name] = {
            asObject: Array.isArray(exp),
            expected: exp,
            keys: SliceTags.fromValue(keys)
        }
    }

    /**
     * Remove quick runnable.
     *
     * @param {string} name - Quick runnable name.
     */
    removeQuickRun (name) {
        if (this.quickRunToType[name]) {
            delete this.quickRunList[this.quickRunToType[name]][name]
            delete this.quickRunToType[name]
        }
    }

    /**
     * Run runflow workers.
     *
     * @param {Array|string} keys - Worker keys.
     * @param {object} data - Workers start data.
     * @param {string} [workerType] - Worker type, of not set - '_'.
     * @param {Function} [work] - Work to do.
     * @returns {object} Workers execution result.
     */
    run (keys, data, workerType, work) {
        const workflow = !workerType && typeof keys === 'string' && this.quickRunToType[keys]
            ? this.runflows[this.quickRunToType[keys]]
            : this.runflows[workerType && workerType !== true
                ? workerType
                : '_']
        return workflow.run(keys, data, work)
    }

    /**
     * Run workflow workers.
     *
     * @param {Array|string} keys - Worker keys.
     * @param {object} data - Workers start data.
     * @param {Function} work - Work to do.
     * @returns {object} Workers execution result.
     */
    do (keys, data, work) {
        return this.workflow.run(keys, data, work)
    }

    /**
     * Run workflow workers.
     *
     * @param {object} data - Workers start data.
     * @param {Function} work - Work to do.
     * @returns {object} Workers execution result.
     */
    doAll (data, work) {
        return this.workflow.runAll(data, work)
    }
}

Slice.WorkflowManager = SliceWorkflowManager


/**
 * Slice.
 * Copyright 2019 shininglab (https://shininglab-code.com/slice).
 */


/**
 * Slice workflow manager.
 */
class SliceWorkflowPlugin {
    /**
     * Slice workflow plugin constructor.
     *
     * @param {SliceWorkflowManager} factory - SliceWorkflowManager object.
     */
    constructor (factory) {
        // SliceWorkflowFactory to create SliceWorkflowManager.
        this.factory = null
        // Invalidated parts within the update process.
        this.invalidated = {}
        this.requirements = null
        if (factory) {
            this.setFactory(factory)
        }
    }

    /**
     * Set factory.
     *
     * @param {SliceWorkflowFactory} factory - SliceWorkflowFactory object.
     * @returns {SliceWorkflowPlugin} Current SliceWorkflowPlugin.
     */
    setFactory (factory) {
        if (factory instanceof SliceWorkflowFactory) {
            this.factory = factory
        }
        return this
    }

    /**
     * Hire manager.
     *
     * @param {object} requirements - Requirements for SliceWorkflowManager.
     * @returns {SliceWorkflowPlugin} Current SliceWorkflowPlugin.
     */
    hireManager (requirements) {
        this.requirements = requirements
        this.manager = this.factory.createManager(requirements)
        return this
    }

    /**
     * Invalidates the given part of the update routine.
     *
     * @param {string} [part] - Part to invalidate.
     * @returns {Array.<string>} Invalidated parts.
     */
    invalidate (part) {
        if (typeof part === 'string') {
            this.invalidated[part] = true
        }
        return Object.keys(this.invalidated)
    }

    /**
     * Run runflow workers.
     *
     * @param {Array|string} keys - Worker keys.
     * @param {object} data - Workers start data.
     * @param {string} [workerType] - Worker type, of not set - '_'.
     * @returns {object} Workers execution result.
     */
    run (keys, data, workerType) {
        return this.manager.run(
            keys, data, workerType,
            (worker, cache) => worker.run.apply(this, this.getManagerData(cache))
        )
    }

    /**
     * Run runflow workers.
     *
     * @returns {SliceWorkflowPlugin} Current SliceWorkflowPlugin.
     */
    update () {
        const invalidated = this.invalidated
        const work = (worker, cache) => {
            let result = worker.run.apply(this, this.getManagerData(cache))
            if (result === 're-update') {
                Object.assign(this.invalidated, invalidated)
                this.update()
                result = false
            }
            return result
        }
        this.invalidated = {}
        if (invalidated.all) {
            this.manager.doAll(null, work)
        } else {
            this.manager.do(Object.keys(invalidated), null, work)
        }
        return this
    }

    /**
     * Get arguments for SliceWorkflowManager workers.
     *
     * @param {object} cache - Worker cache.
     * @returns {Array} Array with workers arguments.
     */
    getManagerData (cache) {
        return [cache]
    }
}

Slice.WorkflowPlugin = SliceWorkflowPlugin


/**
 * Slice.
 * Copyright 2019 shininglab (https://shininglab-code.com/slice).
 */


const sliceEaseDefaults = {
    // Easing value rounding or other action: true or 'round', 'ceil', 'foor' or other Math function, or function
    action: false,
    // Rounding function arguments
    actionArgs: false,
    // Easing function
    ease: 'linear',
    // Easing function arguments
    easeArgs: false,
    // Easing value range
    range: [0, 100]
}

const easeOut = fn => (t, x) => 1 - fn(1 - t, x)
const easeInOut = fn => (t, x) => t < 0.5
    ? fn(2 * t, x) / 2
    : (2 - fn(2 * (1 - t), x)) / 2
const easeIn = (t, x) => {
    const newX = x && x > 0
        ? x
        : 2
    return Math.pow(t, newX)
}

/**
 * Easings list.
 */
const easeList = {
    easeIn,
    easeInOut: easeInOut(easeIn),
    easeOut: easeOut(easeIn),
    linear: progress => progress
}

/**
 * Slice ease.
 */
class SliceEase {
    /**
     * Default options.
     */
    static defaults = {}

    /**
     * Make progress-like number.
     *
     * @param {void} [progress] - Current progress.
     * @returns {number} Progress-like number.
     */
    static toNumber (progress) {
        if (typeof progress === 'number' && !isNaN(progress)) {
            return Math.max(Math.min(progress, 1), 0)
        }
        return progress
            ? 1
            : 0
    }

    /**
     * Get/set easing function.
     * Will get easing if only name argument is provided,
     * and will try to set easing(s) if more arguments are provided.
     *
     * @param {string} name - Easing name.
     * @param {Function} func - Easing function.
     * @param {boolean|string} inFN - Ease in function, or wheather to create it.
     * @param {boolean|string} outFn - Ease out function, or wheather to create it.
     * @param {boolean|string} inOutFn - Ease in-out function, or wheather to create it.
     * @returns {object|SliceEase} Returns easing on getting or SliceEase on setting.
     */
    static ease (name, func, inFN, outFn, inOutFn) {
        if (arguments.length < 2) {
            if (typeof name === 'string' && easeList.hasOwnProperty(name)) {
                return easeList[name]
            }
            return null
        }
        const upCamel = Slice.ucfirst(name)
        if (inFN === true || typeof inFN === 'undefined') {
            easeList[`easeIn${upCamel}`] = func
        } else if (typeof inFN === 'function') {
            easeList[`easeIn${upCamel}`] = inFN
        } else {
            easeList[name] = func
        }
        if (outFn === true || typeof outFn === 'undefined') {
            easeList[`easeOut${upCamel}`] = easeOut(func)
        } else if (typeof outFn === 'function') {
            easeList[`easeOut${upCamel}`] = outFn
        }
        if (inOutFn === true || typeof inOutFn === 'undefined') {
            easeList[`easeInOut${upCamel}`] = easeInOut(func)
        } else if (typeof inOutFn === 'function') {
            easeList[`easeInOut${upCamel}`] = inOutFn
        }
        return SliceEase
    }

    /**
     * Check if easing exists.
     *
     * @param {string} name - Easing name.
     * @returns {boolean} Has easing.
     */
    static has (name) {
        return easeList.hasOwnProperty(name)
    }

    /**
     * Check if easing exists.
     *
     * @param {Array} range - Easing range.
     * @param {void} [ease] - Easing name or function.
     * @param {void} [args] - Easing arguments.
     * @param {boolean} [manual] - Run progress manualy.
     * @returns {boolean} Has easing.
     */
    static create (range, ease, args, manual) {
        return new SliceEase({
            args,
            ease,
            manual,
            range
        })
    }

    /**
     * Constructor.
     *
     * @param {object} [options] - The options.
     */
    constructor (options) {
        // Ease options.
        const built = Object.assign(this.getDefaults(), options || {})
        this
            .setEase(built.ease, built.args)
            .setRange(built.range)
            .setAction(built.action, built.actionArgs)
        if (!this.manual) {
            this.progress(built.progress)
        }
    }

    /**
     * Get default options.
     *
     * @returns {object} Options.
     */
    getDefaults () {
        return {
            ...sliceEaseDefaults,
            ...SliceEase.defaults || {}
        }
    }

    /**
     * Set ease function.
     *
     * @param {void} [ease] - Easing name or function.
     * @param {void} [args] - Easing arguments.
     * @returns {SliceEase} Current easing.
     */
    setEase (ease, args) {
        let easing
        if (ease) {
            const type = typeof ease
            if (type === 'function') {
                easing = ease
            } else if (type === 'string' && SliceEase.has(ease)) {
                easing = easeList[ease]
            } else {
                easing = easeList.linear
            }
        } else {
            easing = easeList.linear
        }
        this.ease = easing
        this.args = Array.isArray(args)
            ? args
            : [args]
        return this
    }

    /**
     * Set ease action or rounding function.
     *
     * @param {void} [action] - Easing name or function.
     * @param {void} [args] - Easing arguments.
     * @returns {SliceEase} Current easing.
     */
    setAction (action, args) {
        let built
        if (action) {
            const type = typeof action
            if (type === 'function') {
                built = action
            } else {
                built = type === 'string' && typeof Math[action] === 'function'
                    ? action
                    : 'round'
                built = Math[built].bind(Math)
            }
            this.action = built
            this.actionArgs = Array.isArray(args)
                ? args
                : [args]
        } else {
            this.action = null
            this.actionArgs = null
        }
        return this
    }

    /**
     * Set ease range.
     *
     * @param {void} [range] - Easing range.
     * @returns {SliceEase} Current easing.
     */
    setRange (range) {
        this.start = 0
        this.end = 100
        if (Array.isArray(range)) {
            if (range.length > 0 && typeof range[0] === 'number') {
                this.start = range[0]
            }
            if (range.length > 1 && typeof range[1] === 'number') {
                this.end = range[1]
            }
        }
        const diff = this.end - this.start
        this.diff = Math.abs(diff)
        this.mult = this.diff
            ? diff / this.diff
            : 1
        this.value = this.start
        return this
    }

    /**
     * Set progress, get ease value.
     *
     * @param {void} [progress] - Easing progress.
     * @param {boolean} [force] - Force re-count.
     * @returns {number} Easing value.
     */
    progress (progress, force) {
        let built = false
        if (typeof progress !== 'undefined') {
            built = SliceEase.toNumber(progress)
        } else if (force) {
            built = this.delta
        }
        if (built !== false) {
            let value = this.start + this.mult * this.diff * this.ease(built, this.args)
            if (this.action) {
                value = this.action(value, ...this.actionArgs)
            }
            this.delta = built
            this.value = value
        }
        return this.value
    }
}

// Add circ easing.
SliceEase.ease('circ', t => 1 - Math.sin(Math.acos(t)))
// Add back easing
SliceEase.ease('back', (t, x) => {
    const newX = x || 1.5
    return Math.pow(t, 2) * ((newX + 1) * t - newX)
})
// Add bounce easing
SliceEase.ease('bounce', t => {
    for (let a = 0, b = 1; ; a += b, b /= 2) {
        if (t >= (7 - 4 * a) / 11) {
            return -Math.pow((11 - 6 * a - 11 * t) / 4, 2) + Math.pow(b, 2)
        }
    }
})
// Add elastic easing
SliceEase.ease('elastic', (t, x) => {
    const newX = x && x > 0
        ? x
        : 10
    return Math.pow(2, newX * (t - 1)) * Math.sin(10.5 * Math.PI * t)
})
// Add wave easing
SliceEase.ease('wave', (t, x) => {
    const newX = (x && x > 0
        ? x
        : 2) - 1
    return (newX
        ? Math.pow(2 - 1 / newX, 2 * (t - 1))
        : 1) * Math.sin((2 * newX + 0.5) * Math.PI * t)
})

Slice.Ease = SliceEase


/**
 * Slice.
 * Copyright 2019 shininglab (https://shininglab-code.com/slice).
 */


/**
 * Slice checkpoint match.
 * Simple helper class.
 */
class SliceCheckpointData {
    /**
     * Create checkpoint match.
     *
     * @param {void} value - Checkpoint data.
     * @param {void} [checkpointType] - Checkpoint type, if not set - create SliceCheckpoint.
     * @param {void} [baseData] - Base data.
     * @returns {SliceCheckpoint} New SliceCheckpoint instance.
     */
    static create (value, checkpointType, baseData) {
        if (value instanceof SliceCheckpointData) {
            return value
        }
        const data = {
            ...baseData
                ? SliceCheckpointData.create(baseData, checkpointType).data
                : {},
            ...value instanceof SliceCheckpoint
                ? value.getMatchData()
                : (SliceCheckpoint.getRegistered(checkpointType) || SliceCheckpoint).createMatchData(value)
        }
        return new SliceCheckpointData(data)
    }

    /**
     * Constructor.
     *
     * @param {object} data - Match data.
     */
    constructor (data) {
        this.data = data
    }
}

Slice.CheckpointData = SliceCheckpointData


/**
 * Slice.
 * Copyright 2019 shininglab (https://shininglab-code.com/slice).
 */


/**
 * Default options.
 */
const checkpointDefaults = {
    // Tags
    tags: false
}

/**
 * Checkpoint types.
 */
const checkpointTypes = {}

/**
 * Slice checkpoint.
 */
class SliceCheckpoint {
    /**
     * Default options.
     */
    static defaults = {}

    /**
     * Register checkpoint class for create.
     *
     * @param {string} name - Registration name.
     * @param {void} checkpoint - Checkpoint class.
     */
    static register (name, checkpoint) {
        if (checkpoint.prototype instanceof SliceCheckpoint) {
            const names = SliceTags.fromValue(name, true)
            Slice.arrayEach(names, regName => {
                checkpointTypes[regName] = checkpoint
            })
        }
    }

    /**
     * Check if checkpoint type is registered.
     *
     * @param {void} value - Value to check.
     * @returns {boolean} Pass check.
     */
    static isRegistered (value) {
        const tags = SliceTags.fromValue(value, true)
        if (tags.length) {
            return Slice.some(tags, tag => checkpointTypes.hasOwnProperty(tag))
        }
        try {
            if (value.prototype instanceof SliceCheckpoint) {
                return Slice.objectSome(
                    checkpointTypes,
                    CheckpointType => value.prototype instanceof CheckpointType
                )
            }
        } catch {
            /* Empty */
        }
        return false
    }

    /**
     * Get first registered checkpoint type.
     *
     * @param {void} value - Value to check.
     * @returns {SliceCheckpoint|boolean} SliceCheckpoint class or false.
     */
    static getRegistered (value) {
        const tags = SliceTags.fromValue(value, true)
        let Checkpoint = false
        if (tags.length) {
            Slice.some(tags, tag => {
                if (checkpointTypes.hasOwnProperty(tag)) {
                    Checkpoint = checkpointTypes[tag]
                    return true
                }
            })
        } else {
            try {
                if (value.prototype instanceof SliceCheckpoint) {
                    return Slice.objectSome(
                        checkpointTypes,
                        CheckpointType => {
                            if (value.prototype instanceof CheckpointType) {
                                Checkpoint = value
                                return true
                            }
                        }
                    )
                }
            } catch {
                /* Empty */
            }
        }
        return Checkpoint
    }

    /**
     * Create checkpoint.
     *
     * @param {object} options - Checkpoint options.
     * @param {void} [checkpointType] - Checkpoint type, if not set - create SliceCheckpoint.
     * @returns {SliceCheckpoint} New SliceCheckpoint instance.
     */
    static create (options, checkpointType) {
        return new (SliceCheckpoint.getRegistered(checkpointType) || SliceCheckpoint)(options)
    }

    /**
     * Constructor.
     *
     * @param {object} [options] - The options.
     */
    constructor (options) {
        // Set options.
        this.options(options instanceof SliceCheckpointData
            ? SliceCheckpointData.data
            : options)
    }

    /**
     * @inheritdoc
     */
    getDefaults () {
        return {
            ...checkpointDefaults,
            ...SliceCheckpoint.defaults
        }
    }

    /**
     * Setup.
     * For intermal use only.
     *
     * @param {object} options - The options.
     */
    setup (options) {
        this.tags = new SliceTags(options.tags)
    }

    /**
     * Set options.
     *
     * @param {object} options - The options.
     * @returns {self} Current instance.
     */
    options (options) {
        this.options = Object.assign(this.getDefaults(), options || {})
        this.setup(options)
        return this
    }

    /**
     * Check if has tags.
     *
     * @param {string|Array} tags - Single or multiple tags.
     * @returns {boolean} Has tags.
     */
    hasTags (tags) {
        return this.tags.has(tags)
    }

    /**
     * Check if checkpoint is crossed by.
     *
     * @param {void} value - Value to check.
     * @returns {boolean} Has tags.
     */
    isCrossedBy (value) {
        const match = SliceCheckpointData.create(value, 'tags')
        return this.tags.allIn(match.data.tags)
    }

    /**
     * Check if checkpoint is before another.
     *
     * @param {void} value - Checkpoint.
     * @returns {boolean} Has tags.
     */
    isBefore (value) {
        const match = SliceCheckpointData.create(value, 'tags')
        return this.tags.allIn(match.data.tags)
    }

    /**
     * Create object to compare.
     *
     * @returns {object} Match data.
     */
    getMatchData () {
        return {
            tags: this.tags
        }
    }

    /**
     * Create object to compare.
     *
     * @param {void} value - Source data.
     * @returns {object} Match data.
     */
    static createMatchData (value) {
        if (value instanceof SliceCheckpoint) {
            return value.getMatchData()
        }
        let data = {}
        let tags = value
        if (typeof value === 'object') {
            data = {
                ...value
            }
            tags = value.tags
        }
        data.tags = SliceTags.fromValue(tags)
        return data
    }
}

SliceCheckpoint.register('base, core, tags', SliceCheckpoint)

Slice.Checkpoint = SliceCheckpoint


/**
 * Slice.
 * Copyright 2019 shininglab (https://shininglab-code.com/slice).
 */


/**
 * Default options.
 */
const progressCheckpointDefaults = {
    // Progress
    progress: false,
    // Strict, will also match tags
    strict: false
}

/**
 * Slice progress checkpoint.
 */
class SliceProgressCheckpoint extends SliceCheckpoint {
    /**
     * @inheritdoc
     */
    static defaults = {}

    /**
     * @inheritdoc
     */
    getDefaults () {
        return {
            ...super.getDefaults(),
            ...progressCheckpointDefaults,
            ...SliceProgressCheckpoint.defaults
        }
    }

    /**
     * @inheritdoc
     */
    setup (options) {
        this.progress = SliceEase.toNumber(options.progress)
        super.setup(options)
    }

    /**
     * @inheritdoc
     */
    isCrossedBy (value) {
        const match = SliceCheckpointData.create(value, 'progress')
        return this.progress <= match.progress &&
            (!(this.options.strict || match.strict) || super.isCrossedBy(match))
    }

    /**
     * @inheritdoc
     */
    isBefore (value) {
        const match = SliceCheckpointData.create(value, 'progress')
        return this.progress <= match.progress
    }

    /**
     * @inheritdoc
     */
    getMatchData () {
        return {
            ...super.getMatchData(),
            progress: this.progress,
            strict: this.options.strict
        }
    }

    /**
     * @inheritdoc
     */
    static createMatchData (value) {
        if (value instanceof SliceProgressCheckpoint) {
            return value.getMatchData()
        }
        let data = {}
        let progress = value
        let strict = false
        if (typeof value === 'object') {
            data = value
            progress = value.progress
            strict = !value.strict
        }
        data.progress = SliceEase.toNumber(progress)
        data.strict = strict
        return SliceProgressCheckpoint.createMatchData(data)
    }
}

SliceCheckpoint.register('progress, percent', SliceProgressCheckpoint)


/**
 * Slice.
 * Copyright 2019 shininglab (https://shininglab-code.com/slice).
 */


/**
 * Default options.
 */
const sliceDistanceCheckpointDefaults = {
    // Distance
    distance: false,
    // Distance limit
    distanceLimit: false
}

/**
 * Slice progress checkpoint.
 */
class SliceDistanceCheckpoint extends SliceProgressCheckpoint {
    /**
     * @inheritdoc
     */
    static defaults = {}

    /**
     * @inheritdoc
     */
    getDefaults () {
        return {
            ...super.getDefaults(),
            ...sliceDistanceCheckpointDefaults,
            ...SliceDistanceCheckpoint.defaults
        }
    }

    /**
     * @inheritdoc
     */
    setup (options) {
        this.distance = options.distance || 0
        const limit = options.distanceLimit === false
            ? 0
            : Math.max(options.distanceLimit, 0)
        super.setup({
            ...options,
            progress: limit
                ? this.distance / limit
                : 0
        })
    }

    /**
     * @inheritdoc
     */
    isCrossedBy (value) {
        const match = SliceCheckpointData.create(value, 'distance')
        return this.distance <= match.distance &&
            (!(this.options.strict || match.strict) || super.isCrossedBy(match))
    }

    /**
     * @inheritdoc
     */
    isBefore (value) {
        const match = SliceCheckpointData.create(value, 'distance')
        return this.distance <= match.distance
    }

    /**
     * @inheritdoc
     */
    getMatchData () {
        return {
            ...super.getMatchData(),
            distance: this.distance
        }
    }

    /**
     * @inheritdoc
     */
    static createMatchData (value) {
        if (value instanceof SliceDistanceCheckpoint) {
            return value.getMatchData()
        }
        let data = {}
        let distance = value
        let limit = 0
        if (typeof value === 'object') {
            data = value
            distance = value.distance
            limit = Math.max(value.distanceLimit || 0, 0)
        }
        data.progress = limit
            ? distance / limit
            : 0
        data.distance = distance
        return SliceProgressCheckpoint.createMatchData(data)
    }
}

SliceCheckpoint.register('distance', SliceDistanceCheckpoint)


/**
 * Slice.
 * Copyright 2019 shininglab (https://shininglab-code.com/slice).
 */


/**
 * Default options.
 */
const sliceTimePassCheckpointDefaults = {
    // Time limit passed
    timeLimit: false,
    // Time passed
    timePass: false
}

/**
 * Slice progress checkpoint.
 */
class SliceTimePassCheckpoint extends SliceProgressCheckpoint {
    /**
     * @inheritdoc
     */
    static defaults = {}

    /**
     * @inheritdoc
     */
    getDefaults () {
        return {
            ...super.getDefaults(),
            ...sliceTimePassCheckpointDefaults,
            ...SliceTimePassCheckpoint.defaults
        }
    }

    /**
     * @inheritdoc
     */
    setup (options) {
        this.timePass = SliceDelay.toDuration(options.timePass)
        const limit = options.timeLimit === false
            ? 0
            : SliceDelay.toDuration(options.timeLimit)
        super.setup({
            ...options,
            progress: limit
                ? this.timePass / limit
                : 0
        })
    }

    /**
     * @inheritdoc
     */
    isCrossedBy (value) {
        const match = SliceCheckpointData.create(value, 'timePass')
        return this.timePass <= match.timePass &&
            (!(this.options.strict || match.strict) || super.isCrossedBy(match))
    }

    /**
     * @inheritdoc
     */
    isBefore (value) {
        const match = SliceCheckpointData.create(value, 'timePass')
        return this.timePass <= match.timePass
    }

    /**
     * @inheritdoc
     */
    getMatchData () {
        return {
            ...super.getMatchData(),
            timePass: this.timePass
        }
    }

    /**
     * @inheritdoc
     */
    static createMatchData (value) {
        if (value instanceof SliceTimePassCheckpoint) {
            return value.getMatchData()
        }
        let data = {}
        let timePass = value
        let limit = 0
        if (typeof value === 'object') {
            data = value
            timePass = value.timePass
            limit = SliceDelay.toDuration(value.timeLimit)
        }
        data.progress = limit
            ? timePass / limit
            : 0
        data.timePass = SliceDelay.toDuration(timePass)
        return SliceProgressCheckpoint.createMatchData(data)
    }
}

SliceCheckpoint.register('duration, delay, timepass, timePass, timer', SliceTimePassCheckpoint)

Slice.TimePassCheckpoint = SliceTimePassCheckpoint


/**
 * Slice.
 * Copyright 2019 shininglab (https://shininglab-code.com/slice).
 */


/**
 * Slice trigger default options.
 */
const actionDefaults = {
    // Action to execute
    action: false,
    // Number of times it's been executed
    count: 0,
    // Been executed atleas once
    executed: false,
    // Repeatable action
    repeatable: true
}

/**
 * Check if current action is the same as provided action.
 *
 * @param {object|Function} action - Action to check.
 * @returns {boolean} The same action or not.
 */
function isThisAction (action) {
    switch (typeof action) {
    case 'object':
        return action.isAction && action.do === this.do
    case 'function':
        return action === this.do
    default:
        return false
    }
}

/**
 * Slice action.
 */
class SliceAction {
    /**
     * Default options.
     */
    static defaults = {}

    /**
     * Create checkpoint.
     *
     * @param {object} value - Action source.
     * @returns {SliceAction} New SliceAction instance.
     */
    static create (value) {
        if (value instanceof SliceAction) {
            return value
        }
        return new SliceAction(typeof value === 'object'
            ? value
            : {
                action: value
            })
    }

    /**
     * Constructor.
     *
     * @param {object} [options] - The options.
     */
    constructor (options) {
        this.iteration = 0
        // Trigger options.
        this.setOptions(Object.assign(this.getDefaults(), options || {}))
    }

    /**
     * Get default options.
     *
     * @returns {object} Options.
     */
    getDefaults () {
        return {
            ...actionDefaults,
            ...SliceAction.defaults || {}
        }
    }

    /**
     * Set options.
     *
     * @param {object} options - The options.
     * @returns {self} Current instance.
     */
    setOptions (options) {
        const builtOptions = Object.assign(this.getDefaults(), options || {})
        this.options = builtOptions
        this.id = builtOptions.hasOwnProperty('id')
            ? builtOptions.id
            : null
        this.repeat = builtOptions.repeat
        if (builtOptions.hasOwnProperty('executed')) {
            this.executed = !!builtOptions.executed
            if (typeof builtOptions.executed === 'number') {
                this.iteration = Math.max(builtOptions.executed, 0)
            } else {
                this.iteration = this.executed
                    ? 1
                    : 0
            }
        }
        this.action = typeof builtOptions.action === 'function'
            ? builtOptions.action
            : Slice.noop
        return this
    }

    /**
     * Check action is the same.
     *
     * @param {void} action - Action to check.
     * @param {boolean} strict - Strict match.
     * @returns {boolean} Pass check.
     */
    is (action, strict) {
        if (action instanceof SliceAction) {
            if (action === this) {
                return true
            } else if (strict) {
                return false
            }
            return action.action === this.action ||
                this.id !== null && this.id === action.id
        }
        switch (typeof action) {
        case 'function':
            return action === this.action
        default:
            return this.id !== null && this.id === action
        }
    }

    /**
     * Do current action.
     *
     * @param {void} data - Data to pass to action.
     * @returns {void} Action result.
     */
    do (data) {
        if (this.executed && !this.repeat) {
            return null
        }
        return this.action(data)
    }
}

Slice.Action = SliceAction


/**
 * Slice.
 * Copyright 2019 shininglab (https://shininglab-code.com/slice).
 */


/**
 * Slice trigger default options.
 */
const triggerDefaults = {
    // Array of actions
    actions: false,
    // Is ready
    ready: true,
    // Repeat automaticaly after triggering or by calling repeat method manualy
    repeatManualy: false,
    // Ready state on repeat
    repeatReady: true,
    // Repeat after triggering
    repeatable: false,
    // Start automaticaly after creation or by calling start method manualy
    startManualy: false
}

const sliceTriggerStates = {
    canceled: 'completed, skipped',
    clean: true,
    done: 'completed, executed',
    failed: 'completed, skipped',
    init: true,
    preparation: 'pending',
    ready: 'execute',
    removed: true,
    started: 'pending',
    triggered: 'execute',
    updateTags: 'updateCheckpoints'
}

/**
 * Checkpoint types.
 */
const SliceTriggerTypes = {}

/**
 * Slice trigger.
 */
class SliceTrigger {
    /**
     * Default options.
     */
    static defaults = {}

    /**
     * Checkpoint type.
     */
    checkpointType = 'tags'

    /**
     * Run actions.
     *
     * @param {object} actions - Object with actions to execute.
     * @param {self} trigger - Current instance.
     * @param {void} data - Data passed to actions.
     */
    static run (actions, trigger, data) {
        if (typeof actions === 'object') {
            Slice.objectEach(actions, action => {
                action.do(trigger, data)
            })
        }
    }

    /**
     * Register checkpoint class for create.
     *
     * @param {string} name - Registration name.
     * @param {void} checkpoint - Checkpoint class.
     */
    static register (name, checkpoint) {
        if (checkpoint.prototype instanceof SliceTrigger) {
            const names = SliceTags.fromValue(name, true)
            Slice.arrayEach(names, regName => {
                SliceTriggerTypes[regName] = checkpoint
            })
        }
    }

    /**
     * Check if checkpoint type is registered.
     *
     * @param {void} value - Value to check.
     * @returns {boolean} Pass check.
     */
    static isRegistered (value) {
        const tags = SliceTags.fromValue(value, true)
        if (tags.length) {
            return Slice.some(tags, tag => SliceTriggerTypes.hasOwnProperty(tag))
        }
        try {
            if (value.prototype instanceof SliceTrigger) {
                return Slice.objectSome(
                    SliceTriggerTypes,
                    TriggerType => value.prototype instanceof TriggerType
                )
            }
        } catch {
            /* Empty */
        }
        return false
    }

    /**
     * Get first registered checkpoint type.
     *
     * @param {void} value - Value to check.
     * @returns {SliceTrigger|boolean} SliceTrigger class or false.
     */
    static getRegistered (value) {
        const tags = SliceTags.fromValue(value, true)
        let Checkpoint = false
        if (tags.length) {
            Slice.some(tags, tag => {
                if (SliceTriggerTypes.hasOwnProperty(tag)) {
                    Checkpoint = SliceTriggerTypes[tag]
                    return true
                }
            })
        } else {
            try {
                if (value.prototype instanceof SliceTrigger) {
                    return Slice.objectSome(
                        SliceTriggerTypes,
                        TriggerType => {
                            if (value.prototype instanceof TriggerType) {
                                Checkpoint = value
                                return true
                            }
                        }
                    )
                }
            } catch {
                /* Empty */
            }
        }
        return Checkpoint
    }

    /**
     * Create checkpoint.
     *
     * @param {object} options - Checkpoint options.
     * @param {void} [checkpointType] - Checkpoint type, if not set - create SliceTrigger.
     * @returns {SliceTrigger} New SliceTrigger instance.
     */
    static create (options, checkpointType) {
        return new (SliceTrigger.getRegistered(checkpointType) || SliceTrigger)(options)
    }

    /**
     * Constructor.
     *
     * @param {object} [options] - The options.
     */
    constructor (options) {
        // Options.
        this.options = {}
        // Actions list.
        this.actions = {}
        // Quick values.
        this.quickValues = {}
        // Preset data.
        this.preset = {}
        // Flow data.
        this.flow = {}
        // Max times triggered.
        this.maxIterations = Infinity
        // Trigger state.
        this.state = new SliceState(sliceTriggerStates)
        this.state.enter('clean')
        this.setOptions(options || {}, true)
    }

    /**
     * Get default options.
     *
     * @returns {object} Options.
     */
    getDefaults () {
        return {
            ...triggerDefaults,
            ...SliceTrigger.defaults || {}
        }
    }

    /**
     * Get default options.
     *
     * @returns {object} Options.
     */
    getDefaultQuickValues () {
        return {}
    }

    /**
     * Set options.
     *
     * @param {object} options - The options.
     * @param {boolean} [fromScratch] - Same as creating totaly new instance.
     * @returns {self} Current instance.
     */
    setOptions (options, fromScratch) {
        if (fromScratch) {
            this.cleanup()
            this.options = Object.assign(this.getDefaults(), options || {})
            this.init()
        } else if (options) {
            this.updateOptions(options)
            this.options = Object.assign(this.options, options || {})
            if (this.iteration === 1) {
                this.prepareReset(this.preset)
            } else {
                this.prepareRepeat(this.preset)
            }
            if (this.state.is('started')) {
                this.adjustFlow(this.flow, this.preset)
                this.update()
            }
        }
        return this
    }

    /**
     * Update options.
     * For intermal use only.
     *
     * @param {object} options - The options.
     */
    updateOptions (options) {
        if (options.actions && Array.isArray(options.actions)) {
            Slice.arrayEach(options.actions, action => {
                this.addAction(action)
            })
        }
        const actProp = 'Actions'
        const actPropLen = actProp.length
        Slice.objectEach(options, (value, name) => {
            if (Array.isArray(value) && name.indexOf(actProp, name.length - actPropLen) !== -1) {
                const actionType = name.slice(0, name.length - actPropLen)
                Slice.arrayEach(value, action => {
                    this.addAction(action, actionType)
                })
            }
        })
        const quickValues = this.getDefaultQuickValues()
        if (typeof options.quickValues === 'object') {
            Object.assign(quickValues, options.quickValues)
        }
        Slice.objectEach(quickValues, (value, name) => {
            this.setQuickValue(name, value)
        })
        if (typeof options.repeat === 'number') {
            this.maxIterations = Math.max(Math.ceil(options.repeat), 0) + 1
        }
    }

    /**
     * Cleanup.
     * For intermal use only.
     *
     * @returns {boolean} Cleaned or not.
     */
    cleanup () {
        if (this.state.is('clean')) {
            return false
        }
        this.options = {}
        this.actions = {}
        this.preset = {}
        this.flow = {}
        this.maxIterations = Infinity
        this.state.reset().is('clean')
        return true
    }

    /**
     * Initialize.
     * For intermal use only.
     */
    init () {
        this.state.enter('init')
        this.updateOptions(this.options)
        this.state.exit('init')
        this.reset(null, true)
    }

    /**
     * Prepare to reset.
     * For internal use only.
     *
     * @param {object} preset - Iteration preset.
     */
    prepareReset (preset) {
        this.iteration = 1
        preset.ready = this.options.ready
    }

    /**
     * Prepare to start.
     * For internal use only.
     *
     * @param {object} preset - Iteration preset.
     */
    prepareRepeat (preset) {
        preset.ready = this.options.repeatReady
    }

    /**
     * Adjust flow.
     * For internal use only.
     *
     * @param {object} flow - Iteration flow.
     * @param {void} value - Adjust value.
     */
    adjustFlow (flow, value) {
        this.adjustSettings(flow, value)
    }

    /**
     * Adjust settings.
     * For internal use only.
     *
     * @param {object} settings - Settings.
     * @param {void} value - Adjust value.
     */
    adjustSettings (settings, value) {
        if (typeof value === 'object') {
            if (value.hasOwnProperty('ready')) {
                settings.ready = !!value.ready
            }
        } else {
            settings.ready = !!value
        }
    }

    /**
     * Prepare to start.
     * For internal use only.
     *
     * @param {object} flow - Iteration flow.
     * @param {object} preset - Iteration preset.
     */
    prepareStart (flow, preset) {
        Object.assign(flow, preset)
        flow.values = {}
        flow.tags = new SliceTags()
        flow.checkpoints = []
    }

    /**
     * Reset.
     *
     * @param {void} [options] - Start options.
     * @param {boolean} total - Total reset or just current iteration.
     * @returns {self} Current instance.
     */
    reset (options, total) {
        const startManualy = this.options.startManualy
        if (total) {
            this.state.reset().enter('preparation')
            SliceTrigger.run(this.actions.totalReset, this)
            this.preset = {}
            this.prepareReset(this.preset)
            if (this.state.not('completed')) {
                if (this.iteration <= this.maxIterations) {
                    if (startManualy) {
                        this.stop(true)
                    } else {
                        this.start(options)
                    }
                } else {
                    this.cancel()
                }
            }
        } else if (this.canStart()) {
            const restart = this.state.not('started')
            this.state.reset().enter('preparation')
            SliceTrigger.run(this.actions.reset, this)
            if (restart) {
                if (!startManualy || this.iteration > 1 && !this.options.repeatManualy) {
                    return this.start()
                }
            } else {
                this.state.enter('started')
            }
            if (options !== null && typeof options !== 'undefined') {
                this.adjustSettings(this.preset, options)
            }
            this.flow = {}
            this.prepareStart(this.flow, this.preset)
            this.update()
        }
        return this
    }

    /**
     * Check if can start.
     *
     * @returns {boolean} Can start.
     */
    canStart () {
        return this.state.not('failed')
    }

    /**
     * Start.
     *
     * @param {void} [options] - Start options.
     * @returns {self} Current instance.
     */
    start (options) {
        if (this.canStart()) {
            this.flow = {}
            this.state.reset().enter('started')
            if (options !== null && typeof options !== 'undefined') {
                this.adjustSettings(this.preset, options)
            }
            this.prepareStart(this.flow, this.preset)
            SliceTrigger.run(this.actions.start, this)
            this.update()
        }
        return this
    }

    /**
     * Repeat.
     *
     * @param {void} [options] - Start options.
     * @returns {self} Current instance.
     */
    repeat (options) {
        const state = this.state
        if (state.is('triggered')) {
            if (this.canRepeat()) {
                this.preset = {}
                this.state.reset().enter('preparation')
                SliceTrigger.run(this.actions.repeat, this)
                this.prepareRepeat(this.preset)
                this.start(options)
            } else {
                this.finish()
            }
        } else {
            this.reset(options)
        }
        return this
    }

    /**
     * Stop.
     *
     * @param {boolean} silent - Don't run actions.
     * @returns {self} Current instance.
     */
    stop (silent) {
        if (this.canStart()) {
            this.flow = {}
            this.state.reset()
            this.prepareStart(this.flow, this.preset)
            if (!silent) {
                SliceTrigger.run(this.actions.stop, this)
            }
            this.update()
        }
        return this
    }

    /**
     * Check if ready.
     *
     * @returns {boolean} Is ready.
     */
    isReady () {
        return this.flow.ready
    }

    /**
     * Prepare to update before triggering.
     * Used to stabilize flow values.
     * For internal use only.
     *
     * @param {object} flow - Iteration flow.
     */
    prepareReady (flow) {
        flow.ready = true
    }

    /**
     * Update.
     *
     * @param {boolean} [ready] - Trigger ready.
     * @returns {self} Current instance.
     */
    update (ready) {
        if (this.state.is('started')) {
            this.flow.values = {}
            if (ready) {
                this.prepareReady(this.flow)
            }
            SliceTrigger.run(this.actions.update, this)
            this.updateCheckpoints()
            if (this.isReady()) {
                this.state
                    .reset()
                    .enter('ready')
                this.trigger()
            }
        }
        return this
    }

    /**
     * Trigger action.
     *
     * @param {object} data - Action data object.
     * @returns {self} Current instance.
     */
    trigger (data) {
        const state = this.state
        if (state.is('ready')) {
            ++this.iteration
            state.exit('ready').enter('triggered')
            this.do(data)
            if (!this.options.repeatManualy) {
                this.repeat()
            } else if (!this.canRepeat()) {
                this.finish()
            }
        }
        return this
    }

    /**
     * Check if can repeat.
     *
     * @returns {self} Current instance.
     */
    canRepeat () {
        return this.options.repeat && this.iteration < this.maxIterations
    }

    /**
     * Do trigger action.
     *
     * @returns {self} Current instance.
     */
    do () {
        SliceTrigger.run(this.actions.trigger, this)
        return this
    }

    /**
     * Cancel action.
     *
     * @returns {boolean} Canceled.
     */
    cancel () {
        if (this.state.is('completed')) {
            return false
        }
        this.state.reset().enter('canceled')
        SliceTrigger.run(this.actions.cancel, this)
        return true
    }

    /**
     * Finish trigger actions.
     * Will do 'done' actions and stop triggering any further actions.
     *
     * @returns {boolean} Canceled.
     */
    finish () {
        if (this.state.is('started')) {
            this.flow.values = {}
            this.state.reset().enter('done')
            SliceTrigger.run(this.actions.done, this)
            return true
        }
        return false
    }

    /**
     * Add action.
     *
     * @param {Function|SliceAction|object} action - Action to execute.
     * @param {string} type - Action type.
     * @param {boolean} execute - Execute action after adding.
     * @param {object} data - Execute action data object.
     * @returns {self} Current instance.
     */
    addAction (action, type, execute, data) {
        const builtAction = SliceAction.create(action)
        const builtType = type || 'trigger'
        let actions = this.actions[builtType]
        if (!actions) {
            actions = {}
            this.actions[builtType] = actions
        }
        if (builtAction.id === null) {
            builtAction.id = Slice.uniqueId(actions)
        }
        actions[builtAction.id] = builtAction
        if (execute || this.state.is('done')) {
            builtAction.do(data)
        }
        return this
    }

    /**
     * Remove action.
     *
     * @param {Function} action - Action to execute.
     * @param {string} type - Action type.
     * @param {boolean} execute - Execute action after adding.
     * @param {object} data - Execute action data object.
     * @returns {self} Current instance.
     */
    removeAction (action, type, execute, data) {
        const actionType = type || 'trigger'
        const bindAction = this.getAction(action, actionType)
        if (bindAction) {
            delete this.actions[actionType][bindAction.id]
            if (execute) {
                bindAction.do(data)
            }
        }
        return this
    }

    /**
     * Get action.
     *
     * @param {Function} action - Action to execute.
     * @param {string} type - Action type.
     * @returns {object} Action object.
     */
    getAction (action, type) {
        const actions = this.actions[type || 'trigger']
        if (actions) {
            for (const id in actions) {
                if (actions[id].is(action)) {
                    return actions[id]
                }
            }
        }
        return null
    }

    /**
     * Set quick value.
     *
     * @param {string} name - Quick value name.
     * @param {void} value - Quick value, or function to get it.
     * @returns {self} Current instance.
     */
    setQuickValue (name, value) {
        this.quickValues[name] = typeof value === 'function'
            ? this.quickValues[name] = value
            : () => value
        return this
    }

    /**
     * Remove quick value.
     *
     * @param {string} name - Quick value name.
     * @returns {self} Current instance.
     */
    removeQuickValue (name) {
        if (this.quickValues.hasOwnProperty(name)) {
            delete this.quickValues[name]
        }
        if (this.flow.values.hasOwnProperty(name)) {
            delete this.flow.values[name]
        }
        return this
    }

    /**
     * Get value(s).
     *
     * @param {string} [name] - Easing name.
     * @returns {void} Ease value, null or all values if 'name' is not provided.
     */
    get (name) {
        const flow = this.flow
        if (typeof name === 'undefined') {
            Slice.objectEach(this.quickValues, (quickValue, quickName) => {
                if (!flow.values.hasOwnProperty(quickName)) {
                    flow.values[quickName] = quickValue(this)
                }
            })
            return flow.values
        }
        if (this.quickValues.hasOwnProperty(name)) {
            if (!flow.values.hasOwnProperty(name)) {
                flow.values[name] = this.quickValues[name](this)
            }
            return flow.values[name]
        }
        return flow.values.hasOwnProperty(name)
            ? flow.values[name]
            : null
    }

    /**
     * Add checkpoint.
     *
     * @param {void} value - Checkpoint or options to create it.
     * @param {void} [type] - Checkpoint keys.
     * @param {boolean} [update] - Update checkpoints.
     * @returns {self} Current instance.
     */
    addCheckpoint (value, type, update) {
        if (this.state.is('started')) {
            const checkpoint = value instanceof SliceCheckpoint
                ? value
                : SliceCheckpoint.create(
                    SliceCheckpointData.create(value, this.checkpointType, this.getCheckData()),
                    type || this.checkpointType
                )
            this.flow.checkpoints.push(checkpoint)
            if (update) {
                this.updateCheckpoints()
            }
        }
        return this
    }

    /**
     * Remove checkpoint.
     *
     * @param {void} [value] - Checkpoint or tags, if not provided - remove all.
     * @returns {self} Current instance.
     */
    removeCheckpoint (value) {
        if (this.state.is('started')) {
            const flow = this.flow
            if (typeof value === 'undefined') {
                flow.checkpoints = []
            } else if (value instanceof SliceCheckpoint) {
                Slice.some(flow.checkpoints, (checkpoint, i) => {
                    if (checkpoint === value) {
                        flow.checkpoints.splice(i, 1)
                        return true
                    }
                })
            } else {
                const builtKeys = SliceTags.fromValue(value, true)
                if (builtKeys.length) {
                    const checkpoints = flow.checkpoints
                    let i = checkpoints.length - 1
                    for (; i >= 0; i--) {
                        if (checkpoints[i].hasTags(builtKeys)) {
                            checkpoints.splice(i, 1)
                        }
                    }
                }
            }
        }
        return this
    }

    /**
     * Add tags.
     *
     * @param {string|Array} tags - Tags.
     * @returns {self} Current instance.
     */
    addTags (tags) {
        if (this.state.is('started')) {
            this.flow.tags.add(tags)
            this.state.enter('updateTags')
        }
        return this
    }

    /**
     * Update checkpoints.
     *
     * @returns {self} Current instance.
     */
    updateCheckpoints () {
        if (this.state.is('updateCheckpoints') && this.flow.checkpoints.length) {
            const checkpoints = this.getCheckpoints()
            const checkData = SliceCheckpointData.create(this.getCheckData(), this.checkpointType)
            Slice.some(checkpoints.slice(), checkpoint => {
                if (!this.checkCheckpoint(checkpoint, checkData)) {
                    return true
                }
            })
        }
        return this
    }

    /**
     * Get checkpoints.
     *
     * @returns {Array} Array of checkpoints.
     */
    getCheckpoints () {
        return this.state.is('started')
            ? this.flow.checkpoints
            : []
    }

    /**
     * Get checkpoint data.
     * For internal use only.
     *
     * @returns {object} Checkpoint data.
     */
    getCheckData () {
        return {
            tags: this.flow.tags
        }
    }

    /**
     * Check checkpoint.
     * For internal use only.
     *
     * @param {SliceCheckpoint} checkpoint - SliceCheckpoint instance.
     * @param {object} checkData - Check data.
     * @returns {boolean} Passed check.
     */
    checkCheckpoint (checkpoint, checkData) {
        if (checkpoint.isBefore(checkData)) {
            SliceTrigger.run(this.actions.checkpoint, this, {
                checkpoint
            })
            this.removeCheckpoint(checkpoint)
        }
        return true
    }

    /**
     * Remove.
     */
    remove () {
        this.cleanup()
        this.state.enter('removed')
    }
}

SliceTrigger.register('base, core, direct', SliceTrigger)

Slice.Trigger = SliceTrigger


/**
 * Slice.
 * Copyright 2019 shininglab (https://shininglab-code.com/slice).
 */


/**
 * Slice time action default options.
 */
const progressDefaults = {
    // Default easing function
    ease: 'linear',
    // Default easing arguments
    easeArguments: false,
    // State of progress, true or 1 on ready, or float from 0 to 1 for progress
    ready: false,
    // State of progress on repeating, true or 1 on ready, or float from 0 to 1 for progress
    repeatReady: false
}

/**
 * Default quick values.
 */
const progressQuickValuesDefaults = {
    progress: trigger => trigger.state.is('started')
        ? trigger.flow.progress
        : 0
}

const progressBuildSettings = (settings, progress) => {
    settings.progress = SliceEase.toNumber(progress)
    settings.ready = settings.progress === 1
}

/**
 * Slice progress.
 */
class SliceProgress extends SliceTrigger {
    /**
     * @inheritdoc
     */
    static defaults = {}

    /**
     * @inheritdoc
     */
    checkpointType = 'progress'

    /**
     * @inheritdoc
     */
    static quickValuesDefaults = {}

    /**
     * @inheritdoc
     */
    getDefaults () {
        return {
            ...super.getDefaults(),
            ...progressDefaults,
            ...SliceProgress.defaults
        }
    }

    /**
     * @inheritdoc
     */
    getDefaultQuickValues () {
        return {
            ...super.getDefaultQuickValues(),
            ...progressQuickValuesDefaults,
            ...SliceProgress.quickValuesDefaults
        }
    }

    /**
     * @inheritdoc
     */
    updateOptions (options) {
        this.setEase('percent', SliceEase.create([0, 100], options.ease, options.easeArguments, true))
        super.updateOptions(options)
    }

    /**
     * @inheritdoc
     */
    init () {
        this.easings = {}
        this.state.add('updateProgress', 'updateCheckpoints')
        super.init()
    }

    /**
     * @inheritdoc
     */
    isReady () {
        return this.flow.progress === 1
    }

    /**
     * @inheritdoc
     */
    prepareReset (preset) {
        this.iteration = 1
        progressBuildSettings(preset, this.options.ready)
    }

    /**
     * @inheritdoc
     */
    prepareRepeat (preset) {
        progressBuildSettings(preset, this.options.repeatReady)
    }

    /**
     * @inheritdoc
     */
    adjustSettings (settings, value) {
        if (typeof value === 'object') {
            if (value.hasOwnProperty('progress')) {
                progressBuildSettings(settings, value.progress)
            }
        } else {
            progressBuildSettings(settings, value)
        }
    }

    /**
     * Update.
     *
     * @param {number} [progress] - Current progress.
     * @returns {SliceProgress} Current instance.
     */
    update (progress) {
        return this.setProgress(progress)
    }

    /**
     * Set progress.
     *
     * @param {number} [progress] - Current progress.
     * @returns {boolean} Is ready.
     */
    setProgress (progress) {
        if (this.state.is('started')) {
            const flow = this.flow
            if (typeof progress === 'number') {
                flow.progress = SliceEase.toNumber(progress)
                this.state.enter('updateProgress')
            }
            const ready = this.isReady()
            super.update(ready)
            return ready
        }
        return false
    }

    /**
     * Set easing.
     *
     * @param {string} name - Easing name.
     * @param {SliceEase} ease - Easing object.
     * @returns {SliceProgress} Current instance.
     */
    setEase (name, ease) {
        if (ease instanceof SliceEase) {
            this.easings[name] = ease
            if (this.quickValues.hasOwnProperty(name)) {
                delete this.quickValues[name]
            }
        }
        return this
    }

    /**
     * Remove progress action.
     *
     * @param {string} name - Easing name.
     * @returns {SliceProgress} Current instance.
     */
    removeEase (name) {
        if (this.easings.hasOwnProperty(name)) {
            delete this.easings[name]
        }
        if (this.flow.values.hasOwnProperty(name)) {
            delete this.flow.values[name]
        }
        return this
    }

    /**
     * @inheritdoc
     */
    setQuickValue (name, value) {
        if (value instanceof SliceEase) {
            return this.setEase(name, value)
        }
        if (this.easings.hasOwnProperty(name)) {
            this.removeEase(name)
        }
        return super.setQuickValue(name, value)
    }

    /**
     * @inheritdoc
     */
    get (name) {
        const flow = this.flow
        if (typeof name === 'undefined') {
            super.get()
            Slice.objectEach(this.easings, (ease, easeName) => {
                if (!flow.values.hasOwnProperty(easeName)) {
                    flow.values[easeName] = ease.progress(flow.progress)
                }
            })
            return flow.values
        }
        if (this.easings.hasOwnProperty(name)) {
            if (!flow.values.hasOwnProperty(name)) {
                flow.values[name] = this.easings[name].progress(flow.progress)
            }
            return flow.values[name]
        }
        return super.get(name)
    }

    /**
     * @inheritdoc
     */
    getCheckData () {
        return {
            ...super.getCheckData(),
            progress: this.flow.progress,
            updateProgress: this.state.is('updateProgress'),
            updateTags: this.state.is('updateTags')
        }
    }

    /**
     * @inheritdoc
     */
    addCheckpoint (value, type, update) {
        if (this.state.is('started')) {
            this.flow.checkpointsASC = false
            super.addCheckpoint(value, type, update)
        }
        return this
    }

    /**
     * @inheritdoc
     */
    getCheckpoints () {
        let checkpoints = []
        if (this.state.is('started')) {
            const flow = this.flow
            checkpoints = flow.checkpoints
            // Move all non SliceProgressCheckpoint checkpoint forward and sort the rest
            if (!flow.checkpointsASC) {
                checkpoints.sort((item1, item2) => item1 instanceof SliceProgressCheckpoint
                    ? item2 instanceof SliceProgressCheckpoint && item1.isBefore(item2)
                        ? -1
                        : 1
                    : item2 instanceof SliceProgressCheckpoint
                        ? -1
                        : 0)
                flow.checkpointsASC = true
            }
        }
        return checkpoints
    }

    /**
     * @inheritdoc
     */
    checkCheckpoint (checkpoint, checkData) {
        if (checkData instanceof SliceProgressCheckpoint) {
            if (checkpoint.isCrossedBy(checkData)) {
                SliceTrigger.run(this.actions.checkpoint, this, {
                    checkpoint
                })
                this.removeCheckpoint(checkpoint)
                return true
            } else if (checkpoint.isBefore(checkData)) {
                return true
            }
            return false
        } else if (!checkData.data.updateTags) {
            return true
        }
        return super.checkCheckpoint(checkpoint, checkData)
    }

    /**
     * @inheritdoc
     */
    finish () {
        if (this.state.is('started')) {
            this.flow.progress = 1
            return super.finish()
        }
        return false
    }

    /**
     * @inheritdoc
     */
    cleanup () {
        if (super.cleanup()) {
            this.easings = null
            return true
        }
        return false
    }
}

SliceTrigger.register('progress, percent', SliceProgress)

Slice.Progress = SliceProgress


/* global moment */
/**
 * Slice.
 * Copyright 2019 shininglab (https://shininglab-code.com/slice).
 */


/**
 * Default options.
 */
const delayDefaults = {
    // Delay to start timer
    delay: false,
    // Time to do action
    fps: 60,
    // Repeat delay in milliseconds
    repeatDelay: false
}

/**
 * Default quick values.
 */
const delayQuickValuesDefaults = {
    delayLeft: trigger => trigger.state.is('waiting')
        ? Math.max(trigger.flow.delay - trigger.flow.delayTime, 0)
        : 0,
    delayTime: trigger => trigger.state.is('waiting')
        ? Math.min(trigger.flow.delayTime, trigger.flow.delay)
        : trigger.state.is('playing')
            ? trigger.flow.delay
            : 0,
    tickPass: trigger => trigger.state.is('playing')
        ? trigger.flow.tickPass
        : 0
}

const delayTick = trigger => {
    SliceDelay.removeTimeout(trigger)
    const flow = trigger.flow
    const now = Date.now()
    const passed = now - flow.tickTime
    flow.values = {}
    flow.tickPass = passed
    flow.delayTime += passed
    flow.passedTime += passed
    flow.tickTime = now
    if (flow.delayTime < flow.delay) {
        SliceTrigger.run(trigger.actions.wait, trigger)
        SliceDelay.addTimeout(trigger, () => {
            delayTick(trigger)
        })
    } else {
        trigger.state
            .exit('waiting')
            .enter('playing')
        flow.delay = Math.min(flow.delayTime, flow.delay)
        flow.delayTime = flow.delay
        SliceTrigger.run(trigger.actions.play, trigger)
        trigger.startPlay()
    }
}

const delayHoldTick = (trigger, action, fn) => {
    SliceDelay.removeTimeout(trigger)
    const flow = trigger.flow
    const now = Date.now()
    flow.values = {}
    flow.holdTime += now - flow.tickHoldTime
    flow.tickHoldTime = now
    if (flow.holdTime < flow.continueDelay) {
        if (action) {
            SliceTrigger.run(trigger.actions[action], trigger)
        }
        SliceDelay.addTimeout(trigger, () => {
            delayHoldTick(trigger, action, fn)
        })
    } else {
        fn()
    }
}

const delayBuildSettings = (settings, delay) => {
    settings.delay = SliceDelay.toDuration(delay)
}

/**
 * Slice delay.
 * Base class, not meant to be used as trigger.
 */
class SliceDelay extends SliceProgress {
    /**
     * @inheritdoc
     */
    static defaults = {}

    /**
     * @inheritdoc
     */
    static quickValuesDefaults = {}

    /**
     * Get duration in milliseconds from now.
     *
     * @param {number} time - Time in milliseconds.
     * @param {boolean} isDate - Time is date.
     * @returns {moment|null} Moment object.
     */
    static fromTime (time, isDate) {
        if (typeof time === 'number') {
            // If time is more that 1 year it's most likely a date
            return Math.max(isDate || isDate !== false && time > 31556952000
                ? time - Date.now()
                : time, 0)
        }
        return 0
    }

    /**
     * Modify duration and return new.
     *
     * @param {string|Date|moment} duration - Base duration.
     * @param {string|Date|moment} value - Modify value.
     * @param {boolean} substract - Substract value.
     * @returns {number} Duration.
     */
    static modifyDuration (duration, value, substract) {
        let time = SliceDelay.toDuration(duration)
        switch (typeof value) {
        case 'object':
            if (value instanceof Date) {
                return SliceDelay.fromTime(value.getTime(), true)
            }
            if (moment.isDuration(value)) {
                return value.asMilliseconds()
            }
            if (moment.isMoment(value)) {
                return SliceDelay.fromTime(value.valueOf(), true)
            }
            time = Slice.getObjectValue(value, 'getTime')
            if (time === null) {
                time = Slice.getObjectValue(value, 'milliseconds')
            }
            break
        case 'number':
            time = value
            break
        }
        return SliceDelay.toDuration(duration) + (substract
            ? -time
            : time)
    }

    /**
     * Get duration in milliseconds from now.
     *
     * @param {string|Date|moment} value - Milliseconds.
     * @returns {moment|null} Moment object.
     */
    static toDuration (value) {
        let time
        switch (typeof value) {
        case 'object':
            if (value instanceof Date) {
                return SliceDelay.fromTime(value.getTime(), true)
            }
            if (moment.isDuration(value)) {
                return value.asMilliseconds()
            }
            if (moment.isMoment(value)) {
                return SliceDelay.fromTime(value.valueOf(), true)
            }
            time = Slice.getObjectValue(value, 'getTime')
            if (time === null) {
                time = Slice.getObjectValue(value, 'milliseconds')
            }
            break
        case 'number':
            time = value
            break
        }
        return SliceDelay.fromTime(time)
    }

    /**
     * Clear trigger timeout if any.
     *
     * @param {SliceTrigger} trigger - Trigger instance.
     */
    static removeTimeout (trigger) {
        if (trigger.timeout) {
            clearTimeout(trigger.timeout)
            trigger.timeout = false
        }
    }

    /**
     * Set trigger timeout.
     *
     * @param {SliceTrigger} trigger - Trigger instance.
     * @param {Function} timeFunc - Timeout function.
     */
    static addTimeout (trigger, timeFunc) {
        if (trigger.timeout) {
            clearTimeout(trigger.timeout)
        }
        trigger.timeout = setTimeout(() => {
            trigger.timeout = false
            timeFunc()
        }, trigger.fpsTime || 0)
    }

    /**
     * @inheritdoc
     */
    updateOptions (options) {
        this.fpsTime = 1000 / options.fps
        super.updateOptions(options)
    }

    /**
     * @inheritdoc
     */
    init () {
        this.state
            .add('waiting', 'pending')
            .add('playing', 'pending')
            .add('paused', 'pending')
        super.init()
    }

    /**
     * @inheritdoc
     */
    getDefaults () {
        return {
            ...super.getDefaults(),
            ...delayDefaults,
            ...SliceDelay.defaults
        }
    }

    /**
     * @inheritdoc
     */
    getDefaultQuickValues () {
        return {
            ...super.getDefaultQuickValues(),
            ...delayQuickValuesDefaults,
            ...SliceDelay.quickValuesDefaults
        }
    }

    /**
     * Extend delay by some ammout.
     *
     * @param {string|Date|moment} value - Milliseconds.
     * @returns {SliceDelay} Current instance.
     */
    extendDelay (value) {
        if (this.state.is('waiting')) {
            this.flow.delay = SliceDelay.modifyDuration(this.flow.duration, value)
            this.update()
        }
        return this
    }

    /**
     * Reduce delay by some ammout.
     *
     * @param {string|Date|moment} value - Milliseconds.
     * @returns {SliceDelay} Current instance.
     */
    reduceDelay (value) {
        if (this.state.is('waiting')) {
            this.flow.delay = SliceDelay.modifyDuration(this.flow.duration, value, true)
            this.update()
        }
        return this
    }

    /**
     * @inheritdoc
     */
    prepareReset (preset) {
        SliceDelay.removeTimeout(this)
        this.iteration = 1
        this.startTime = Date.now()
        delayBuildSettings(preset, this.options.delay)
    }

    /**
     * @inheritdoc
     */
    prepareRepeat (preset) {
        SliceDelay.removeTimeout(this)
        delayBuildSettings(preset, this.options.repeatDelay === false
            ? this.options.delay
            : this.options.repeatDelay)
    }

    /**
     * @inheritdoc
     */
    adjustSettings (settings, value) {
        if (typeof value === 'object') {
            if (value.hasOwnProperty('delay')) {
                delayBuildSettings(settings, value.delay)
            }
        } else {
            delayBuildSettings(settings, value)
        }
    }

    /**
     * @inheritdoc
     */
    prepareStart (flow, preset) {
        SliceDelay.removeTimeout(this)
        super.prepareStart(flow, preset)
        const now = Date.now()
        flow.iterationTime = now
        flow.tickPass = 0
        flow.passedTime = 0
        flow.delayTime = 0
        flow.tickTime = now
        flow.progress = 0
        flow.delayProgress = 0
        flow.playTime = 0
    }

    /**
     * @inheritdoc
     */
    prepareReady () {
        SliceDelay.removeTimeout(this)
    }

    /**
     * Update.
     *
     * @returns {SliceProgress} Current instance.
     */
    update () {
        const state = this.state
        if (state.is('started')) {
            if (state.is('paused')) {
                super.update()
            } else if (state.is('playing')) {
                this.startPlay()
            } else {
                if (state.not('waiting')) {
                    state.enter('waiting')
                }
                delayTick(this)
            }
        }
        return this
    }

    /**
     * Prepare to play.
     * For internal use only.
     *
     * @param {object} flow - Iteration flow.
     */
    preparePlay (flow) {
        flow.playTime = 0
        flow.delay = Math.min(flow.delayTime, flow.delay)
        flow.delayTime = flow.delay
    }

    /**
     * Start play.
     * For internal use only.
     */
    startPlay () {
        SliceDelay.removeTimeout(this)
    }

    /**
     * Play.
     *
     * @returns {SliceProgress} Current instance.
     */
    play () {
        if (this.state.is('started') && this.state.not('paused')) {
            this.state
                .exit('waiting')
                .enter('playing')
            this.flow.tickTime = Date.now()
            this.preparePlay(this.flow)
            SliceTrigger.run(this.actions.play, this)
            this.startPlay()
        }
        return this
    }

    /**
     * Pause timer.
     *
     * @returns {SliceProgress} Current instance.
     */
    pause () {
        SliceDelay.removeTimeout(this)
        if (this.state.is('started') && this.state.not('paused')) {
            this.flow.pauseTime = Date.now()
            this.state.enter('paused')
            SliceTrigger.run(this.actions.pause, this)
        }
        return this
    }

    /**
     * Pause timer.
     *
     * @param {string|Date|moment} delay - Milliseconds.
     * @returns {SliceProgress} Current instance.
     */
    continue (delay) {
        if (this.state.is('paused')) {
            const delayVal = SliceDelay.toDuration(delay)
            const flow = this.flow
            flow.continueDelay = delayVal
            if (delay) {
                flow.tickHoldTime = Date.now()
                flow.holdTime = 0
                SliceTrigger.run(this.actions.continueDelay, this)
                delayHoldTick(this, 'holdContinue', () => {
                    this.continue(0)
                })
            } else {
                SliceTrigger.run(this.actions.continue, this)
                this.flow.tickTime = Date.now()
                this.state.exit('paused')
                this.update()
            }
        }
        return this
    }

    /**
     * @inheritdoc
     */
    finish () {
        if (this.state.is('started')) {
            SliceDelay.removeTimeout(this)
            this.flow.delayTime = this.flow.delay
            return super.finish()
        }
        return false
    }

    /**
     * @inheritdoc
     */
    cleanup () {
        if (super.cleanup()) {
            SliceDelay.removeTimeout(this)
            return true
        }
        return false
    }
}

Slice.Delay = SliceDelay


/* global moment */
/**
 * Slice.
 * Copyright 2019 shininglab (https://shininglab-code.com/slice).
 */


/**
 * Default options.
 */
const distanceDefaults = {
    // Distance
    distance: false,
    // Repeat distance
    repeatDistance: false,
    // Repeat speed
    repeatSpeed: false,
    // Speed
    speed: false
}

/**
 * Default quick values.
 */
const distanceQuickValuesDefaults = {
    distanceLeft: trigger => trigger.state.is('playing')
        ? Math.max(trigger.flow.distance - trigger.flow.distancePass, 0)
        : trigger.state.is('waiting')
            ? trigger.flow.distance
            : 0,
    distancePass: trigger => trigger.state.is('playing')
        ? Math.min(trigger.flow.distancePass, trigger.flow.distance)
        : 0,
    playLeft: trigger => trigger.state.is('playing')
        ? Math.max(trigger.flow.distanceTime - trigger.flow.playTime, 0)
        : trigger.state.is('waiting')
            ? trigger.flow.distanceTime
            : 0,
    playTime: trigger => trigger.state.is('playing')
        ? Math.min(trigger.flow.playTime, trigger.flow.distanceTime)
        : 0
}

const distanceBuildSettings = (settings, distance, speed) => {
    settings.distance = distance
    if (typeof speed !== 'undefined') {
        settings.speed = Math.max(speed || 0, 0)
    }
    settings.distanceTime = settings.speed
        ? settings.distance / settings.speed
        : Infinity
}

const distanceTick = trigger => {
    SliceDelay.removeTimeout(trigger)
    const flow = trigger.flow
    const now = Date.now()
    const passed = now - flow.tickTime
    flow.tickPass = passed
    flow.passedTime += passed
    flow.playTime += passed
    flow.tickDistance = passed * flow.speed
    flow.distancePass += flow.tickDistance
    flow.tickTime = now
    SliceDelay.addTimeout(trigger, () => {
        distanceTick(trigger)
    })
    trigger.setProgress(flow.distancePass / flow.distance)
}

/**
 * Slice distance.
 */
class SliceDistance extends SliceDelay {
    /**
     * @inheritdoc
     */
    static defaults = {}

    /**
     * @inheritdoc
     */
    checkpointType = 'distance'

    /**
     * @inheritdoc
     */
    static quickValuesDefaults = {}

    /**
     * @inheritdoc
     */
    getDefaults () {
        return {
            ...super.getDefaults(),
            ...distanceDefaults,
            ...SliceDistance.defaults
        }
    }

    /**
     * @inheritdoc
     */
    getDefaultQuickValues () {
        return {
            ...super.getDefaultQuickValues(),
            ...distanceQuickValuesDefaults,
            ...SliceDistance.quickValuesDefaults
        }
    }

    /**
     * Extend distance by some ammout.
     *
     * @param {number} value - Distance.
     * @returns {self} Current instance.
     */
    extendDistance (value) {
        if (this.state.is('started')) {
            this.flow.distance += value
            this.update()
        }
        return this
    }

    /**
     * Reduce distance by some ammout.
     *
     * @param {number} value - Distance.
     * @returns {self} Current instance.
     */
    reduceDuration (value) {
        if (this.state.is('started')) {
            this.flow.duration -= value
            this.update()
        }
        return this
    }

    /**
     * @inheritdoc
     */
    prepareReset (preset) {
        super.prepareReset(preset)
        distanceBuildSettings(preset, this.options.distance, this.options.speed)
    }

    /**
     * @inheritdoc
     */
    prepareRepeat (preset) {
        super.prepareRepeat(preset)
        distanceBuildSettings(preset, this.options.repeatDistance === false
            ? this.options.distance
            : this.options.repeatDistance, this.options.repeatSpeed === false
            ? this.options.speed
            : this.options.repeatSpeed)
    }

    /**
     * @inheritdoc
     */
    adjustSettings (settings, value) {
        if (typeof value === 'object') {
            super.adjustSettings(settings, value)
            distanceBuildSettings(settings, value.hasOwnProperty('distance')
                ? value.distance
                : settings.distance, value.hasOwnProperty('speed')
                ? value.speed
                : settings.speed)
        } else {
            distanceBuildSettings(settings, value)
        }
    }

    /**
     * @inheritdoc
     */
    prepareStart (flow, preset) {
        super.prepareStart(flow, preset)
        flow.distancePass = 0
    }

    /**
     * @inheritdoc
     */
    prepareReady (flow) {
        super.prepareReady(flow)
        flow.distancePass = flow.distance
        flow.playTime = flow.distanceTime
    }

    /**
     * Set passed distance.
     *
     * @param {number} value - Passed distance.
     * @returns {self} Current instance.
     */
    setPassDistance (value) {
        const state = this.state
        if (state.is('started')) {
            const flow = this.flow
            const distance = Math.max(value, 0)
            flow.distancePass = Math.min(distance, flow.distance)
            if (state.is('playing') && state.not('paused')) {
                const now = Date.now()
                const passed = now - flow.tickTime
                flow.tickPass = passed
                flow.passedTime += passed
                flow.playTime += passed
                flow.tickTime = now
                flow.tickDistance = distance - flow.distancePass
            }
            flow.distanceTime = flow.speed
                ? flow.playTime + (flow.distance - flow.distancePass) / flow.speed
                : Infinity
            this.setProgress(flow.distancePass / flow.distance)
        }
        return this
    }

    /**
     * @inheritdoc
     */
    preparePlay (flow) {
        super.preparePlay(flow)
        flow.distancePass = 0
    }

    /**
     * @inheritdoc
     */
    startPlay () {
        if (this.flow.distance) {
            if (this.flow.speed) {
                distanceTick(this)
            }
        } else {
            super.setProgress(1)
        }
    }

    /**
     * @inheritdoc
     */
    getCheckData () {
        const flow = this.flow
        return {
            ...super.getCheckData(),
            distance: flow.distancePass,
            distanceLimit: flow.distance,
            timeLimit: flow.distanceTime,
            timePass: flow.playTime
        }
    }

    /**
     * @inheritdoc
     */
    finish () {
        if (this.state.is('started')) {
            const flow = this.flow
            flow.passedTime = flow.delay + flow.distanceTime
            flow.distancePass = flow.distance
            flow.playTime = flow.distanceTime
            return super.finish()
        }
        return false
    }
}

SliceTrigger.register('distance, speed, distancePass, distancepass', SliceDistance)

Slice.Distance = SliceDistance


/* global moment */
/**
 * Slice.
 * Copyright 2019 shininglab (https://shininglab-code.com/slice).
 */


/**
 * Default options.
 */
const timerDefaults = {
    // Time to do action
    duration: false,
    // Repeat duration in milliseconds
    repeatDuration: false
}

/**
 * Default quick values.
 */
const timerQuickValuesDefaults = {
    playLeft: trigger => trigger.state.is('playing')
        ? Math.max(trigger.flow.duration - trigger.flow.playTime, 0)
        : trigger.state.is('waiting')
            ? trigger.flow.duration
            : 0,
    playTime: trigger => trigger.state.is('playing')
        ? Math.min(trigger.flow.playTime, trigger.flow.duration)
        : 0
}

const timerBuildSettings = (settings, duration) => {
    settings.duration = SliceDelay.toDuration(duration)
}

const timerTick = trigger => {
    SliceDelay.removeTimeout(trigger)
    const flow = trigger.flow
    const now = Date.now()
    const passed = now - flow.tickTime
    flow.tickPass = passed
    flow.passedTime += passed
    flow.playTime += passed
    flow.tickTime = now
    SliceDelay.addTimeout(trigger, () => {
        timerTick(trigger)
    })
    trigger.setProgress(flow.playTime / flow.duration)
}

/**
 * Slice time.
 */
class SliceTimer extends SliceDelay {
    /**
     * @inheritdoc
     */
    static defaults = {}

    /**
     * @inheritdoc
     */
    checkpointType = 'timePass'

    /**
     * @inheritdoc
     */
    static quickValuesDefaults = {}

    /**
     * @inheritdoc
     */
    getDefaults () {
        return {
            ...super.getDefaults(),
            ...timerDefaults,
            ...SliceTimer.defaults
        }
    }

    /**
     * @inheritdoc
     */
    getDefaultQuickValues () {
        return {
            ...super.getDefaultQuickValues(),
            ...timerQuickValuesDefaults,
            ...SliceTimer.quickValuesDefaults
        }
    }

    /**
     * Extend duration by some ammout.
     *
     * @param {string|Date|moment} value - Milliseconds.
     * @returns {self} Current instance.
     */
    extendDuration (value) {
        if (this.state.is('started')) {
            this.flow.duration = SliceDelay.modifyDuration(this.flow.duration, value)
            this.update()
        }
        return this
    }

    /**
     * Reduce duration by some ammout.
     *
     * @param {string|Date|moment} value - Milliseconds.
     * @returns {self} Current instance.
     */
    reduceDuration (value) {
        if (this.state.is('started')) {
            this.flow.duration = SliceDelay.modifyDuration(this.flow.duration, value, true)
            this.update()
        }
        return this
    }

    /**
     * @inheritdoc
     */
    prepareReset (preset) {
        super.prepareReset(preset)
        timerBuildSettings(preset, this.options.duration)
    }

    /**
     * @inheritdoc
     */
    prepareRepeat (preset) {
        super.prepareRepeat(preset)
        timerBuildSettings(preset, this.options.repeatDuration === false
            ? this.options.duration
            : this.options.repeatDuration)
    }

    /**
     * @inheritdoc
     */
    adjustSettings (settings, value) {
        if (typeof value === 'object') {
            super.adjustSettings(settings, value)
            if (value.hasOwnProperty('duration')) {
                timerBuildSettings(settings, value.duration)
            }
        } else {
            timerBuildSettings(settings, value)
        }
    }

    /**
     * @inheritdoc
     */
    prepareReady (flow) {
        super.prepareReady(flow)
        flow.playTime = flow.duration
    }

    /**
     * @inheritdoc
     */
    startPlay () {
        if (this.flow.duration) {
            timerTick(this)
        } else {
            super.setProgress(1)
        }
        return this
    }

    /**
     * @inheritdoc
     */
    getCheckData () {
        return {
            ...super.getCheckData(),
            timeLimit: this.flow.duration,
            timePass: this.flow.playTime
        }
    }

    /**
     * @inheritdoc
     */
    finish () {
        if (this.state.is('started')) {
            const flow = this.flow
            flow.passedTime = flow.delay + flow.duration
            flow.playTime = flow.duration
            return super.finish()
        }
        return false
    }
}

SliceTrigger.register('duration, delay, timer, timepass, timePass', SliceTimer)

Slice.Timer = SliceTimer



window.Slice = Slice
